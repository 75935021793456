import { isNullOrUndefined } from 'util'
import Config from './config'
import Const from './constant'
import * as Storage from './storage'
import { isEmpty } from './utility'

// TODO API呼び出しはutility.jsからこちらへ移す。

const compareByDisplayOrder = (item1, item2) => {
  if (item1.display_order < item2.display_order) {
    return -1
  }
  if (item2.display_order < item1.display_order) {
    return 1
  }
  return 0
}

function appendIfModifiedSinceParam (path, lastUpdatedTime, prevCount = null) {
  if (lastUpdatedTime) {
    let pathWithParam = `${path}?if_modified_since=${encodeURIComponent(lastUpdatedTime)}`
    if (!isNullOrUndefined(prevCount)) {
      pathWithParam += `&if_changed_count=${prevCount}`
    }
    return pathWithParam
  } else {
    return path
  }
}

export default class Api {

  //-------- リクエスト共通 --------

  static authToken = null
  static async getAuthToken () {
    if (!this.authToken) {
      this.authToken = await Storage.getToken()
    }
    return this.authToken
  }

  static async request (method, path, body, useToken = true) {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('X-App-Version', Const.software.version)
    headers.append('X-App-Build', Const.software.build)
    headers.append('Cache-Control', 'no-cache')
    if (useToken) {
      headers.append('Authorization', `Bearer ${await this.getAuthToken()}`)
    }

    let option = { method, headers }
    if (body) {
      option.body = JSON.stringify(body)
    }

    const baseUrl = Config.apiBaseUrl + Config.apiPathPrefix
    let response = await fetch(baseUrl + path, option)
    if (response.ok) {
      const contentType = response.headers.get('Content-Type')
      switch (contentType) {
        case 'application/pdf':
          return response.blob()
        default:
          return response.json()
      }
    } else {
      if (path === '/user' && (method === 'PUT' || method === 'PATCH')) {
        if (response.status === 409) {
          for (let i = 0; i < 10; i++) {
            response = await fetch(baseUrl + path, option)
            if (response.ok) {
              return response.json()
            }
          }
        }
      }
      let error = new Error(response.statusText || '')
      error.response = response
      throw error
    }
  }

  static get (path, useToken = true) {
    return Api.request('GET', path, null, useToken)
  }

  static post (path, body, useToken = true) {
    return Api.request('POST', path, body, useToken)
  }

  static put (path, body, useToken = true) {
    return Api.request('PUT', path, body, useToken)
  }

  static patch (path, body, useToken = true) {
    return Api.request('PATCH', path, body, useToken)
  }

  static delete (path, body, useToken = true) {
    return Api.request('DELETE', path, body, useToken)
  }

  //-------- 各種API --------

  // メールアドレス登録 → 認証コード発行
  static async registerMailAddress (mailAddress, opts) {
    const params = {
      status: 'passcode',
      mail_address: mailAddress,
    }
    const res = await Api.post('/auths', Object.assign(params, opts))
    return res
  }

  // パスワード忘れ
  static resetPassword (mailAddress) {
    return Api.post('/auths/forgot', {
      mail_address: mailAddress
    })
  }

  // 認証コード認証
  static async authByPasscode (authId, passcode) {
    const res = await Api.put(`/auths/${authId}/passcode`, {
      status: 'checked',
      passcode
    }, false)
    return res
  }

  // メールアドレス確認
  static confirmAuth (authId) {
    return Api.put(`/auths/${authId}`, { status: 'checked' })
  }

  // ログイン
  static login (mailAddress, password, userType, mode) {
    let params = {
      mail_address: mailAddress,
      password: password,
      user_type: userType,
      mode: mode,
    }
    return Api.post('/auths/login', params, false)
  }

  // ログアウト
  static logout () {
    return Api.post('/auths/logout', null)
  }

  // トークンの有効性確認
  static getAuth () {
    return Api.get('/auths/check')
  }

  // 住所検索
  static async searchAddress (zipcode, authId) {
    let res = await Api.get(`/addresses/${zipcode}?auth_id=${authId}`)
    if (res.results && res.results.length > 0) {
      return res.results[0]
    } else {
      return null
    }
  }

  // マスタ取得
  static async getMasters (name = null, auth_id = null) {
    let path = '/masters'
    if (name) {
      path += `/${name}`
    }
    if (auth_id) {
      path += `?auth_id=${auth_id}`
    }
    let data = await Api.get(path)
    return data.masters
  }

  // マスタ取得 (単一)
  static async getMaster (type, name) {
    const res = await Api.get(`/masters/${type}/${name}`)
    return res.master
  }

  // ダイアログマスタ取得
  static async getDialodMaster (name) {
    const res = await Api.get(`/masters/dialog/${name}`)
    return res.master
  }

  // トークルーム情報と最新メッセージ取得
  static async getChatRoom (roomId) {
    let data = await Api.get(`/chats/${roomId}`)
    return data.chat
  }

  // トークルームのメッセージ取得
  static async getChatMessage (roomId, msgId) {
    let path = `/chats/${roomId}/messages/${msgId}`
    let data = await Api.get(path)
    return data.message
  }

  // トークルームの過去メッセージ取得
  static async getChatMessages (roomId, beforeMsgId) {
    let path = `/chats/${roomId}/messages?before_id=${beforeMsgId}`
    let data = await Api.get(path)
    return data.messages
  }

  // 秘密の質問の回答取得
  static async getSecretQuestionAnswers (roomId, targetMsgId) {
    let path = `/chats/${roomId}/messages?target_question_message_id=${targetMsgId}`
    let data = await Api.get(path)
    return data.messages
  }

  // 秘密の質問一覧取得
  static async getSecretRooms (roomId) {
    let path = `/chats/${roomId}/messages?secret_questions=true`
    let data = await Api.get(path)
    return data.messages
  }

  // 最後に受信したメッセージID更新
  static updateLastReceivedMessage (roomId, params) {
    return Api.put(`/chats/${roomId}`, { chat: params })
  }

  // トークルームにメッセージ送信
  static async sendChatMessage (roomId, params) {
    const path = `/chats/${roomId}/messages`
    const data = await Api.post(path, { message: params })
    return data.message
  }

  // デートお誘いSTOPに対する回答
  static async answerConfirmSend (roomId, msgId, answer) {
    const path = `/chats/${roomId}/messages/${msgId}`
    const data = await Api.put(path, {
      message: { confirm_result: answer }
    })
    return data.message
  }

  // トークルーム内のアンケート回答
  static async answerQuestion (roomId, msgId, answer) {
    const path = `/chats/${roomId}/messages/${msgId}`
    const data = await Api.put(path, {
      message: { fav_question_answer: answer }
    })
    return data.message
  }

  // 秘密の質問回答
  static async answerSecretQuestion (message, answer) {
    const path = `/chats/${message.chat_room_id}/messages/${message.id}`
    const data = await Api.put(path, {
      message: { secret_question_answer: answer }
    })
    return data.message
  }

  // メッセージ削除
  static async deleteMessage (roomId, msgId) {
    const path = `/chats/${roomId}/messages/${msgId}`
    const data = await Api.delete(path)
    return data.message
  }

  // 既読状態の更新
  static async updateMessagesRead (msgIds) {
    const path = '/chat_messages/read'
    await Api.put(path, { message_ids: msgIds })
  }

  // ご意見・ご要望の送信
  static sendFeedback (params) {
    return Api.post('/feedbacks', { feedback: params })
  }

  // ユーザー自身のUserレコード取得
  static async getUser (lastUpdatedTime = null) {
    let path = appendIfModifiedSinceParam('/user', lastUpdatedTime)
    let data = await Api.get(path)
    return data.user
  }

  // ユーザー自身のUserレコード更新
  static async updateUser (params) {
    let data = await Api.put('/user', { user: params })
    return data.user
  }

  // ユーザー自身のUserConfigレコード取得
  static async getUserConfig () {
    const data = await Api.get('/user_config')
    return data.user_config
  }

  // ユーザー自身のUserConfigレコード更新
  static async updateUserConfig (params) {
    const data = await Api.put('/user_config', { user_config: params })
    return data.user_config
  }

  // メールアドレス変更
  static async updateMailAddress (authId, mailAddress) {
    let path = `/auths/${authId}/mail_address`
    let data = await Api.put(path, { mail_address: mailAddress })
    return data.auth
  }

  // パスワード更新
  static async updatePassword (authId, password, useToken = true) {
    let path = `/auths/${authId}/password`
    let data = await Api.put(path, { password }, useToken)
    return data.auth
  }

  // パスワード変更
  static async changePassword (authId, currentPw, newPw) {
    let path = `/auths/${authId}/password`
    let data = await Api.put(path, {
      current_password: currentPw,
      password: newPw,
    })
    return data.auth
  }

  // 国コードリスト取得
  static async getCountries () {
    const data = await Api.get('/countries')
    return data.countries
  }

  // 電話番号変更
  static async updatePhoneNumber (authId, phoneNumber, countryCode) {
    const path = `/auths/${authId}/phone_number`
    const data = await Api.patch(path, {
      phone_number: phoneNumber,
      country_code: countryCode
    })
    return data.auth
  }

  static async verifyPhoneNumber (authId, code) {
    let path = `/auths/${authId}/sms/verify`
    let data = await Api.post(path, { code }, false)
    return data
  }

  static async resendVerifyPhoneNumber (authId) {
    let path = `/auths/${authId}/sms/resend`
    let data = await Api.post(path, null, false)
    return data
  }

  static async disableSms () {
    let data = await Api.patch('/auths/sms/disable')
    return data.auth
  }

  // トーク返信が難しい日の登録
  static updateBusyDate (startDate, endDate, reason) {
    return Api.put('/user', {
      user: {
        busy_start_date: startDate,
        busy_end_date: endDate,
        busy_reason: reason,
      }
    })
  }

  // 退会
  // 引数の詳細についてはwikiを参照のこと
  // https://github.com/aill-navi/aill_backend/wiki/Users-API#%E9%80%80%E4%BC%9A-%E4%BC%9A%E5%93%A1
  static withdraw (reasons, reasonDetail, allowHearing = false, allowReEntry = false, allowSms) {
    return Api.delete('/user', {
      withdrawal: {
        reasons: reasons,
        reason_detail: reasonDetail,
        allow_hearing: allowHearing,
        allow_re_entry: allowReEntry,
        allow_sms: allowSms,
      }
    })
  }

  // 最新のMatchingレコード取得
  static async getLatestMatchings (lastUpdatedTime = null) {
    let path = appendIfModifiedSinceParam('/matchings', lastUpdatedTime)
    let data = await Api.get(path)
    return data.matchings
  }

  // 自分が送信したフレンド申請を取得
  static async getEntries (since) {
    let path = '/entries'
    if (since) {
      path += `?since=${since}`
    }
    let data = await Api.get(path)
    return data.entries
  }

  // 友達申請・承認の送信
  // ※ 各コンポーネントからこれを直接使用せず、BaseComponent#postEntryを使用してください。
  static async postEntry (friendId, type, opts = null) {
    let params = {
      friend_id: friendId,
      type: type,
    }
    if (opts) {
      Object.assign(params, opts)
    }
    let data = await Api.post('/entries', params)
    return data
  }

  // 保存済みの申請下書きがあれば取得
  static async findEntryDraft (friendId) {
    try {
      let data = await Api.get(`/entry_drafts/${friendId}`)
      return data.entry_draft
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // 下書き未保存
        return null
      } else {
        throw error
      }
    }
  }

  // 申請下書き作成
  static async createEntryDraft (friendId, params) {
    let data = await Api.post('/entry_drafts', {
      entry_draft: Object.assign(params, { target_user_id: friendId })
    })
    return data.entry_draft
  }

  // 申請下書き更新
  static async updateEntryDraft (friendId, params) {
    let data = await Api.put(`/entry_drafts/${friendId}`, {
      entry_draft: params
    })
    return data.entry_draft
  }

  // 申請下書き削除
  static async deleteEntryDraft (friendId) {
    await Api.delete(`/entry_drafts/${friendId}`)
  }

  // 自分が送信したフレンド申請をキャンセル
  static async cancelEntries (targetUserId) {
    let path = `/entries/${targetUserId}`
    return Api.delete(path)
  }

  // 自分のすべてのFriendレコード取得
  static async getFriends (lastUpdatedTime = null, currentFriendIds = null) {
    let path = '/friends'
    if (lastUpdatedTime) {
      path += `?if_modified_since=${encodeURIComponent(lastUpdatedTime)}`
      if (currentFriendIds) {
        path += `&if_modified_friend_ids=${currentFriendIds}`
      }
    }
    let data = await Api.get(path)
    return data.friends
  }

  // Friendレコード取得
  static async getFriend (friendUserId, lastUpdatedTime = null) {
    let path = appendIfModifiedSinceParam(`/friends/${friendUserId}`, lastUpdatedTime)
    let data = await Api.get(path)
    return data.friend
  }

  // Friendレコード更新
  static async updateFriend (friendUserId, params) {
    let data = await Api.patch(`/friends/${friendUserId}`, {
      friend: params
    })
    return data.friend
  }

  // Friendレコード削除
  static async deleteFriend (friendUserId) {
    const data = await Api.delete(`/friends/${friendUserId}`)
    return data.friend
  }

  // 自分に対する他ユーザーのLikeを取得
  static getLikesToMe () {
    return Api.get('/like')
  }

  // 自分の他ユーザーに対するLikeを取得
  static getLikes (friendId) {
    return Api.get(`/likes/${friendId}`)
  }

  // 自分の他ユーザーに対するLikeを更新
  static updateLikes (friendId, params) {
    return Api.patch(`/likes/${friendId}`, params)
  }

  // 好感度レコード取得
  static async getFavorability (friendUserId) {
    let data = await Api.get(`/friends/${friendUserId}/favorability`)
    return data.favorability
  }

  // 好感度レコード更新
  static updateFavorability (friendUserId, params) {
    return Api.put(`/friends/${friendUserId}/favorability`, {
      favorability: params
    })
  }

  // 自分のギャラリー画像リストを取得
  static async getMyGalleryItems (lastUpdatedTime) {
    let path = appendIfModifiedSinceParam('/user/gallery_items', lastUpdatedTime)
    let data = await Api.get(path)
    return data.gallery_items.sort(compareByDisplayOrder)
  }

  // ギャラリー画像のidを払い出すためレコード作成
  static async createMyGalleryItem () {
    let data = await Api.post('/user/gallery_items')
    return data.gallery_item
  }

  // アップロードしたギャラリー画像のS3キーを書き込み＆縮小版作成
  static async updateMyGalleryItem (item, s3Key, imgFilters = null) {
    const params = { s3_key: s3Key }
    if (imgFilters && imgFilters.brightness) {
      params.photo_filter_brightness = imgFilters.brightness
    }
    const data = await Api.put(`/user/gallery_items/${item.id}`, {
      gallery_item: params
    })
    return data.gallery_item
  }

  // ギャラリー画像の表示順更新
  static async updateMyGalleryItemDisplayOrder (itemId, displayOrder) {
    const data = await Api.put(`/user/gallery_items/${itemId}`, {
      gallery_item: { display_order: displayOrder }
    })
    return data.gallery_item
  }

  // ギャラリー画像を削除
  static deleteMyGalleryItem (item) {
    return Api.delete(`/user/gallery_items/${item.id}`)
  }

  // 他のユーザーのギャラリー画像リストを取得
  static async getGalleryItems (userId) {
    let data = await Api.get(`/users/${userId}/gallery_items`)
    return data.gallery_items.sort(compareByDisplayOrder)
  }

  // トーク画像を取得
  static async getChatPhotoItem (roomId, id) {
    let data = await Api.get(`/chats/${roomId}/chat_photos/${id}`)
    return data.chat_photo
  }

  // トーク画像のidを払い出すためレコード作成
  static async createChatPhotoItem (roomId) {
    let data = await Api.post(`/chats/${roomId}/chat_photos`)
    return data.chat_photo
  }

  // アップロードしたトーク画像のS3キーを書き込み＆縮小版作成
  static async updateChatPhotoItem (roomId, item, s3Key) {
    let url = `/chats/${roomId}/chat_photos/${item.id}`
    let data = await Api.put(url, {
      chat_photo: {
        s3_key: s3Key
      }
    })
    return data.chat_photo
  }

  // FCMトークン更新 (Push通知用)
  static async updateFcmToken (token) {
    return await Api.createExtApiToken({
      value: token,
      api_type: 'fcm',
    })
  }

  // 外部APIトークン取得
  static async getExtApiToken (params) {
    const { api_type, validate } = params
    const data = await Api.get(`/ext_api_tokens?api_type=${api_type}&validate=${validate}`)
    const tokens = data.ext_api_tokens
    if (tokens.length === 0) { return null }

    tokens.sort((t1, t2) => {
      // created_at の降順
      if (t1.created_at < t2.created_at) { return 1 }
      if (t2.created_at < t1.created_at) { return -1 }
      return 0
    })
    return tokens[0]
  }

  // 外部APIトークン追加
  static async createExtApiToken (params) {
    const data = await Api.post('/ext_api_tokens', { ext_api_token: params })
    return data.ext_api_token
  }

  // 外部APIトークン更新
  static async updateExtApiToken (id, params) {
    const data = await Api.put(`/ext_api_tokens/${id}`, { ext_api_token: params })
    return data.ext_api_token
  }

  // プロフィール公開情報取得
  static async getProfilePublicSetting (targetUserId) {
    let data = await Api.get(`/profile_public_settings/${targetUserId}`)
    return data.profile_public_setting
  }

  // プロフィール公開情報更新
  static async updateProfilePublicSetting (targetUserId, params) {
    let data = await Api.put(`/profile_public_settings/${targetUserId}`, {
      profile_public_setting: params
    })
    return data.profile_public_setting
  }

  // 自己紹介文テンプレートの値を保存
  static async createUserProfile (category, sub_category, value) {
    const data = await Api.post('/user_profiles', {
      category, sub_category, value
    })
    return data.user_profile
  }

  // ユーザー組織情報取得
  static async getUserOrganization (organizationId, auth_id = null) {
    let url = `/organizations/${organizationId}`
    if (auth_id) {
      url += `?auth_id=${auth_id}`
    }
    let data = await Api.get(url)
    return data.organization
  }

  // 組織情報取得
  static async getOrganizations (area_id = null) {
    let url = '/organizations'
    if (area_id) {
      url += `?area_id=${area_id}`
    }
    let data = await Api.get(url)
    return data.organizations
  }

  // 自分が所属する組織取得
  static async getMyOrganization () {
    let data = await Api.get('/my_organization')
    return data.organization
  }

  // 会社取得
  static async getCompanies (orgId) {
    let data = await Api.get(`/companies?organization_id=${orgId}`)
    return data.companies
  }

  // 会社名検索
  static async searchCompany (companyName, opts) {
    if (!companyName) {
      return null
    }
    let path = `/companies?company_name=${encodeURIComponent(companyName)}`
    if (opts?.include_not_started === true) {
      path += '&include_not_started=true'
    }
    let data = await Api.get(path)
    return data.companies
  }

  // エリア情報取得
  static async getAreas () {
    let data = await Api.get('/areas')
    return data.areas
  }

  // エリア情報取得
  static async getAreaInfo (areaId) {
    const data = await Api.get(`/areas/${areaId}`)
    return data.area
  }

  // 都道府県リスト取得
  static async getPrefs () {
    const data = await Api.get('/prefs')
    return data.prefs
  }

  // 自分の都道府県取得
  static async getMyPref () {
    const data = await Api.get('/pref')
    return data.pref
  }

  // 最寄駅取得
  static async getStations (prefId) {
    const data = await Api.get(`/stations?pref_id=${prefId}`)
    return data.stations
  }

  // 流入元選択肢取得
  static async getInflowSource () {
    const res = await Api.get('/inflow_sources')
    return res.inflow_sources
  }

  // 現在有効な無料期間取得
  static async getFreeTerm (organizationId) {
    const data = await Api.get(`/free_terms?organization_id=${organizationId}`)
    const recs = data.free_terms
    return (recs && 0 < recs.length) ? recs[0] : null
  }

  // 現在有効なプラン値引取得
  static async getPlanDiscount (planId, orgId) {
    const data = await Api.get(`/plan_discounts?plan_id=${planId}&organization_id=${orgId}`)
    const recs = data.plan_discounts
    return (recs && 0 < recs.length) ? recs[0] : null
  }

  // ユーザー行動ログ追加
  static async addActionLog (event, location = null, params = {}) {
    if (isEmpty(await Storage.getToken())) { return }

    if (isEmpty(location)) {
      location = window.location.pathname
    }

    try {
      let data = await Api.post('/logs', {
        log: {
          event: event.toLowerCase(),
          location: location.toLowerCase(),
          service: Config.service,
          deployment: Config.deployMode,
          ...params,
        }
      })
      return data.log
    } catch (error) {
      // エラーダイアログは表示しない
      console.log(error)
    }
  }

  // エラーログ追加
  static async addErrorLog (error) {
    let params = {
      log_level: 'error',
      message: error.message,
      exception_class: error.name,
    }
    try {
      if (error.stack && error.stack.split) {
        params.backtrace = error.stack.split('\n')
      }
      let data = await Api.post('/system_logs', params)
      return data.system_log
    } catch (error) {
      // エラーダイアログは表示しない
      console.log(error)
    }
  }

  // 一時的なAWS認証トークンを取得
  static async createFederationToken () {
    let data = await Api.post('/aws/sts/federation-token', {
      actions: ['s3:Put*'],
      resources: ['*']
    })
    return data.credentials
  }

  // S3オブジェクトの一時的な署名済みURLを取得
  static async createPresignedUrl (s3Key) {
    let items = [
      {
        'id': s3Key,
        'bucket': Const.s3Bucket,
        'key': s3Key,
      }
    ]
    let data = await Api.post('/aws/s3/presigned-urls', { items })
    return data.results[0]
  }

  // お知らせレコード取得
  static async getNotifications (pageKey, itemsPerPage, nickName) {
    let params = ''
    if (pageKey && !nickName) {
      params += `?page_key=${pageKey}`
    }
    if (itemsPerPage && !nickName) {
      if (params) {
        params += `&items_per_page=${itemsPerPage}`
      } else {
        params += `?items_per_page=${itemsPerPage}`
      }
    }
    if (nickName) {
      params += `?nick_name=${nickName}`
    }
    let data = await Api.get('/notifications' + params)
    return data
  }

  // お知らせレコード取得（指定日時以降）
  static async getNotificationsSince (since) {
    let path = '/notifications'
    if (since) {
      path += `?since=${encodeURIComponent(since)}`
    }
    let data = await Api.get(path)
    return data
  }

  // お知らせ未読件数取得
  static async getUnreadNotificationCount () {
    let data = await Api.get('/unread_notification_count')
    return data.unread_count
  }

  // お知らせを既読にする
  static async updateAlreadyReadNotifications (created_times = null) {
    let data = await Api.patch('/notifications', {
      conditions: {
        already_read: false,
        created_times: created_times
      },
      values: {
        already_read: true
      }
    })
    return data.updated_count
  }

  // 全体お知らせレコード取得
  static async getGlobalNotifications () {
    let data = await Api.get('/global_notifications')
    return data.notifications
  }

  // 全体お知らせ未読件数取得
  static async getUnreadGlobalNotificationCount () {
    let data = await Api.get('/unread_global_notification_count')
    return data.unread_count
  }

  // 既読IDを作成する
  static async createReadState (params) {
    let data = await Api.post('/read_states', {
      read_state: params
    })
    return data.read_state
  }

  // お知らせ既読ID取得
  static async getReadIds (target) {
    if (!target) {
      return []
    }
    let data = await Api.get(`/read_ids?target=${target}`)
    return data.read_ids
  }

  // アンケート結果作成
  static async createSurvey (params) {
    let data = await Api.post('/surveys', params)
    return data
  }

  // 前回アンケート結果取得
  static async getLatestSurvey (survey_type) {
    let url = `/surveys/latest?survey_type=${survey_type}`
    let data = await Api.get(url)
    return data.survey
  }

  // アンケートマスタ取得
  static async getSurveyMasters (answer_required = false) {
    let url = ''
    if (answer_required) {
      url = `/survey_masters?answer_required=${answer_required}`
    } else {
      url = '/survey_masters'
    }
    let data = await Api.get(url)
    return data.survey_masters
  }

  // アンケート設問取得
  static async getSurveyItems (survey_type = null) {
    let url = ''
    if (survey_type) {
      url = `/survey_items?survey_type=${survey_type}`
    } else {
      url = '/survey_items'
    }
    let data = await Api.get(url)
    return data.survey_items
  }

  // 友達紹介キャンペーンコード発行
  static async createCampaignCode (params) {
    let data = await Api.post('/campaigns', params)
    return data.campaign
  }

  // キャンペーンマスタ取得
  static async getCampaignMaster (params) {
    const url = '/campaign_master?' + Object.entries(params).map(list => `${list[0]}=${list[1]}`).join('&')
    const data = await Api.get(url)
    return data.campaign_master
  }

  // お店リスト検索
  static async getShops (params) {
    let data = await Api.get('/shops' + params)
    return data
  }

  // キャンペーン対象のお店リスト取得
  static async getShopsOfCampaign (campaignType) {
    const data = await Api.get(`/shops?campaign_type=${campaignType}`)
    return data.shops
  }

  // お店取得
  static async getShop (id) {
    const data = await Api.get(`/shops/${id}`)
    return data.shop
  }

  // お店写真スクレイピング検索
  static async searchPhotoScrapers (url, count) {
    let data = await Api.get(`/photo_scrapers?url=${url}&count=${count}`)
    return data.photos
  }

  // お店情報更新
  static updateShop (shopId, params) {
    let data = Api.patch(`/shops/${shopId}`, params)
    return data.shop
  }

  // パートナーリンク作成
  static async createPartnerLink (params) {
    let data = await Api.post('/partner_links', params)
    return data.partner_link
  }

  // パートナーリンク一覧取得
  static async getPartnerLinks (id) {
    let data = await Api.get(`/partner_links?partner_id=${id}`)
    return data.partner_links
  }

  // パートナーリンク削除
  static async deletePartnerLink (id) {
    await Api.delete(`/partner_links/${id}`)
  }

  // パートナー実績取得
  static async getPartnerResults (params) {
    let data = await Api.get('/partner_results' + params)
    return data.partner_results
  }

  // パートナーユーザー取得
  static async getPartnerUser (id) {
    let data = await Api.get(`/partner_users/${id}`)
    return data.partner_user
  }

  // パートナーユーザー作成
  static async createPartnerUser (params) {
    let data = await Api.post('/partner_users', params)
    return data.partner_user
  }

  // パートナーユーザー更新
  static async updatePartnerUser (id, params) {
    let data = await Api.put(`/partner_users/${id}`, params)
    return data.partner_user
  }

  // パートナーユーザー削除
  static async deletePartnerUser (id) {
    await Api.delete(`/partner_users/${id}`)
  }

  // パートナーユーザー一覧取得
  static async getPartnerUsers (id) {
    let data = await Api.get(`/partner_users?partner_id=${id}`)
    return data.partner_users
  }

  // パートナー取得
  static async getPartners () {
    let data = await Api.get('/partners')
    return data.partners
  }

  // お相手メモ作成
  static async createChatMemo (params) {
    let data = await Api.post('/chat_memos', params)
    return data.chat_memo
  }

  // お相手メモ取得
  static async getChatMemos (room_id) {
    let data = await Api.get(`/chat_memos/?chat_room_id=${room_id}`)
    return data.chat_memos
  }

  // お相手メモ更新
  static async updateChatMemo (id, params) {
    let data = await Api.put(`/chat_memos/${id}`, params)
    return data.chat_memo
  }

  // 現在加入しているプラン
  static async getMyPlan () {
    let data = await Api.get('/my_plan')
    return data.plan
  }

  // 現在のプラン加入状態
  static async getMySubscription () {
    let data = await Api.get('/my_subscription')
    return data.subscription
  }

  static async updateMySubscription (params) {
    const data = await Api.put('/my_subscription', { subscription: params })
    return data.subscription
  }

  // 支払情報を取得
  static async getMyPayment () {
    let data = await Api.get('/my_payment')
    return data.payment
  }

  // 支払情報を作成
  static async createMyPayment (params) {
    let data = await Api.post('/my_payment', {
      payment: params
    })
    return data.payment
  }

  // 請求履歴取得
  static async getMyBillings () {
    const data = await Api.get('/billings')
    return data.billings
  }

  // 次回請求情報取得
  static async getMyNextBilling (nextPlanId, months) {
    const query = `next_plan_id=${nextPlanId}&payment_months=${months}`
    const data = await Api.get(`/my_next_billing?${query}`)
    return data.next_billing
  }

  // 領収書(PDF)取得
  static async getReceipt (billingId) {
    const data = await Api.get(`/receipts/${billingId}`)
    return data
  }

  // 食レポリスト取得
  static async getFoodReports (roomId) {
    const data = await Api.get(`/food_reports?chat_room_id=${roomId}`)
    return data.food_reports
  }

  // 食レポ取得
  static async getFoodReport (foodReportId) {
    const data = await Api.get(`/food_reports/${foodReportId}`)
    return data.food_report
  }

  // 食レポ更新
  static async updateFoodReport (foodReportId, params) {
    const data = await Api.put(`/food_reports/${foodReportId}`, params)
    return data.food_report
  }

  // 表示するべきダイアログリスト取得
  static async getMyDialogs () {
    const data = await Api.get('/my_dialogs')
    return data.dialogs
  }

  // IdText取得
  static async getIdText (id) {
    const data = await Api.get(`/id_texts/${id}`)
    return data.id_text
  }

  // 自分のマッチング状態取得
  static async getMatchingState () {
    const data = await Api.get('/matching_state')
    return data.matching_state
  }

  // 映画イベント取得
  static async getMovieEvents (content_type) {
    const data = await Api.get(`/movie_events?content_type=${content_type}`)
    return data.movie_events
  }

  // 映画情報取得
  static async getMovieInfo (movieId) {
    const data = await Api.get(`/movie_events/${movieId}`)
    return data.movie_event
  }

  // 映画イベント関連の自分と特定ユーザーのレコード取得
  static async getMovieMatchings (friendId) {
    const data = await Api.get(`/movie_matchings?target_user_id=${friendId}`)
    return data.movie_matchings
  }

  // 映画イベントの感想取得
  static async getMovieSurveys (movieId) {
    const data = await Api.get(`/movie_surveys/${movieId}`)
    return data.movie_survey
  }

  // 映画イベントの感想更新
  static async updateMovieSurveys (movieId, params) {
    const data = await Api.put(`/movie_surveys/${movieId}`, params)
    return data.movie_survey
  }

  // ミッション取得
  static async getMissions (eventId) {
    const data = await Api.get(`/missions?event_id=${eventId}`)
    return data.missions
  }

  // ユーザー自身のMatchingConfigレコード取得
  static async getMatchingConfig () {
    const data = await Api.get('/matching_config')
    return data.matching_config
  }

  // ユーザー自身のMatchingConfigレコード更新
  static async updateMatchingConfig (params) {
    const data = await Api.put('/matching_config', { matching_config: params })
    return data.matching_config
  }

  // 月額利用料取得
  static async getMonthlyFees (orgId = null) {
    let url = '/monthly_fees'
    if (orgId) {
      url += `?organization_id=${orgId}`
    }
    const data = await Api.get(url)
    return data.monthly_fees
  }

  // 次の月額利用料取得 (無料から有料になるとき用)
  static async getNextMonthlyFees () {
    const data = await Api.get('/monthly_fees?next=true')
    return data.monthly_fees
  }

  // 自分のTicketリスト取得
  static async getMyTickets () {
    const data = await Api.get('/tickets')
    return data.tickets
  }

  // params: クーポンコードから作成 = campaign_code
  // params: SpecialOfferから購入する = special_offer_id, purchase_count
  static async submitTicket (params) {
    const data = await Api.post('/tickets', { ticket: params })
    return data?.ticket || data?.tickets
  }

  // 単語取得
  static async getInterestedThings () {
    const data = await Api.get('/interested_things')
    return data.interested_things
  }

  // スペシャルオファー一覧取得
  static async getSpecialOffers () {
    const data = await Api.get('/special_offers')
    return data.special_offers
  }

  // スペシャルオファー取得
  static async getSpecialOffer (id) {
    const data = await Api.get(`/special_offers/${id}`)
    return data.special_offer
  }

  // 送信予約作成
  static async sendScheduledChatMessage (params) {
    const data = await Api.post('/scheduled_chat_messages', { scheduled_chat_message: params })
    return data.scheduled_chat_message
  }

  // 送信予約更新
  static async updateScheduledChatMessage (id, params) {
    const data = await Api.patch(`/scheduled_chat_messages/${id}`, { scheduled_chat_message: params })
    return data.scheduled_chat_message
  }

  // 送信予約削除
  static async deleteScheduledChatMessage (id) {
    await Api.delete(`/scheduled_chat_messages/${id}`)
  }

  static async getMyScheduledChatMessages (friendUserId) {
    let url = '/scheduled_chat_messages'
    if (friendUserId) {
      url += `?friend_user_id=${friendUserId}`
    }
    const data = await Api.get(url)
    return data.scheduled_chat_messages
  }
}
