import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import CommonSpeech from 'components/parts/CommonSpeech'
import PhotoDialog from 'components/parts/PhotoDialog'
import PhotoFilter from 'components/parts/PhotoFilter'
import ReplySpeech from 'components/parts/ReplySpeech'

const speachStyle = theme => {
  return {
    // DEV-1357 記号が連続すると折り返されない
    // padding を変更する場合は maxWidth も変更する必要がある
    width: 'fit-content',
    maxWidth: (window.innerWidth || 375) - 137,
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    backgroundColor: '#F5F5F5',
    borderRadius: '25px 25px 25px 5px',
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  }
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    display: 'flex',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  faceAvatar: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 36,
    height: 36,
  },
  speach: speachStyle(theme),
  speachText: {
    '-webkit-user-select': 'none',
  },
  postedTime: {
    verticalAlign: 'bottom',
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3.75),
    '& span' :{
      fontSize: 10,
      lineHeight: 1,
      color: '#555555'
    },
  },
  imageProgressContainer: {
    height: 246,
    textAlign: 'center',
  },
  imageContainer: {
    marginTop: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    '& img': {
      maxWidth: 200,
      height: 'auto',
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  message: {
    fontSize: 14,
    minHeight: 18,
  },
})

export class LeftSpeech extends CommonSpeech {
  constructor (props) {
    super(props)
    this.handleTap = this.handleTap.bind(this)
  }

  handleTap () {
    this.props.faceTap?.()
  }

  render () {
    const { user, friend, message, selectedChatId, classes, photo } = this.props
    const { photoLargeUrl, openPhotoDialog, photoLoading } = this.state
    const icon = (friend?.type === 'closed') ? null : photo || friend?.photo_icon

    return (
      <div>
        <PhotoDialog
          contentId={message.id}
          open={openPhotoDialog}
          imageUrl={photoLargeUrl}
          onClose={this.closePhotoDialog}
        />
        <div id={`message-${message.id}`} className={classes.root}>
          <PhotoFilter className={classes.avatarContainer} brightness={friend && friend.photo_filter_brightness}>
            <Avatar src={icon} className={classes.faceAvatar} onClick={this.handleTap} />
          </PhotoFilter>
          {message.message_type === 'image' ? (
            <div className={classes.imageContainer}>
              {photoLoading &&
                <div className={classes.imageProgressContainer}>
                  <CircularProgress />
                </div>
              }
              { /* CommonSpeech#loadImage()でここにCanvasが挿入される */ }
            </div>
          ) : (
            <div>
              <ReplySpeech
                user={user}
                friend={friend}
                message={message}
                speech="left"
              />
              <div
                className={classes.speach}
                onContextMenu={this.handleContextMenu}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}
                style={{ filter: message.id === selectedChatId ? 'contrast(0.5)' : 'none' }}
              >
                <span
                  id={`message-text-${message.id}`}
                  className={classes.speachText}
                >
                  {this.createChatMessageElements(Const.colors.leftSpeach, classes.message)}
                </span>
              </div>
            </div>
          )}
          <div className={classes.timeContainer}>
            <Typography variant="caption">
              { this.getPostedTimeString() }
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

LeftSpeech.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LeftSpeech)