import React from 'react'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export default function CountrySelect (props) {
  const { items, value, onChange } = props
  if (!items || items.length === 0) { return null }

  const createMenuItem = (item) => {
    const flagIcon = getUnicodeFlagIcon(item.code)
    return (
      <MenuItem value={item.code} key={item.code}>
        {flagIcon} {item.display_name} (+{item.phone_number_prefix})
      </MenuItem>
    )
  }

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value)
    }
  }

  return (
    <StyledFormControl fullWidth size="small">
      <InputLabel id="country-select-label">
        国選択
      </InputLabel>
      <Select
        labelId="country-select-label"
        label="国選択"
        value={value}
        onChange={handleChange}
      >
        {items.map(item => createMenuItem(item))}
      </Select>
    </StyledFormControl>
  )
}

CountrySelect.propTypes = {
  items: PropTypes.object.required,
  value: PropTypes.string,
  onChange: PropTypes.func,
}