import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import CheckIcon from 'images/tutorial/check_icon.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'
import Kagura from 'images/tutorial/kagura.png'
import Img01 from 'images/tutorial/photo_sample_1.png'
import Img02 from 'images/tutorial/photo_sample_2.png'
import Img03 from 'images/tutorial/photo_sample_3.png'
import FemaleImg01 from 'images/tutorial/photo_sample_female_1.png'
import FemaleImg02 from 'images/tutorial/photo_sample_female_2.png'
import FemaleImg03 from 'images/tutorial/photo_sample_female_3.png'
import MaleImg01 from 'images/tutorial/photo_sample_male_1.png'
import MaleImg02 from 'images/tutorial/photo_sample_male_2.png'
import MaleImg03 from 'images/tutorial/photo_sample_male_3.png'
import Sakaki from 'images/tutorial/sakaki.png'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 300,
    margin: 'auto',
  },
  point: {
    background: '#EFF0FF',
    borderRadius: 12,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  item: {
    marginLeft: theme.spacing(6.75),
    marginBottom: theme.spacing(2.5),
    position: 'relative',
    '& img': {
      position: 'absolute',
      left: -28,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    textAlign: 'center',
    lineHeight: 1,
  },
  subtitle: {
    width: 'fit-content',
    fontSize: 12,
    fontWeight: 600,
    color: '#1A1667',
    textAlign: 'center',
    lineHeight: 1,
    margin: 'auto',
    marginBottom: theme.spacing(3.5),
    position: 'relative',
    '& img': {
      position: 'absolute',
    },
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: 1,
  },
  sample: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4.5),
    '& img': {
      width: 96,
      height: 96,
      boxSizing: 'border-box',
      paddingRight: theme.spacing(0.375),
      paddingBottom: theme.spacing(0.75),
      paddingLeft: theme.spacing(0.375),
    },
  },
  serif: {
    marginBottom: theme.spacing(3),
  },
  kagura: {
    ...theme.styles.naviSpeachSecret(Const.colors.angelSecretBorder),
    color: '#8636D6',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.3px',
    lineHeight: '20px',
    maxWidth: 253,
    borderRadius: 25,
    marginBottom: 0,
    marginLeft: theme.spacing(1.5),
    padding: theme.spacing(2),
  },
  sakaki: {
    ...theme.styles.naviSpeachSecret(Const.colors.devilSecretBorder),
    color: '#006EDF',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.3px',
    lineHeight: '20px',
    maxWidth: 253,
    borderRadius: 25,
    marginBottom: 0,
    marginLeft: theme.spacing(1.5),
    padding: theme.spacing(2),
  },
}))

export default function SubPhotosAdvice (props) {
  const { user } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.point}>
        <Typography className={classes.subtitle}>
          <img src={FootAImage} width={18} height={18} style={{ left: -24, top: -4 }} />
          サブ写真のポイント
          <img src={FootUnImage} width={18} height={18} style={{ right: -24, top: -4 }} />
        </Typography>
        <Grid className={classes.item} container alignItems="center">
          <img src={CheckIcon} width={28} height={28} />
          <Typography className={classes.text}>最低2枚以上は登録</Typography>
        </Grid>
        <Grid className={classes.item} container alignItems="center">
          <img src={CheckIcon} width={28} height={28} />
          <Typography className={classes.text}>自分の顔が分かるものを1枚以上</Typography>
        </Grid>
        <Grid className={classes.item} container alignItems="center">
          <img src={CheckIcon} width={28} height={28} />
          <Typography className={classes.text}>趣味の写真で会話のきっかけに</Typography>
        </Grid>
      </div>
      <Typography className={classes.title}>
        オススメサブ写真例
      </Typography>
      <Grid className={classes.sample} container justifyContent="center">
        {user.sex === 'female' ? (
          <>
            <img src={FemaleImg01} width={96} height={96} />
            <img src={FemaleImg02} width={96} height={96} />
            <img src={FemaleImg03} width={96} height={96} />
          </>
        ) : user.sex === 'male' ? (
          <>
            <img src={MaleImg01} width={96} height={96} />
            <img src={MaleImg02} width={96} height={96} />
            <img src={MaleImg03} width={96} height={96} />
          </>
        ) : null}
        <img src={Img01} width={96} height={96} />
        <img src={Img02} width={96} height={96} />
        <img src={Img03} width={96} height={96} />
      </Grid>
      <Typography className={classes.subtitle}>
        <img src={FootAImage} width={18} height={18} style={{ left: -24, top: -4 }} />
        狛犬のちょっとアドバイス
        <img src={FootUnImage} width={18} height={18} style={{ right: -24, top: -4 }} />
      </Typography>
      <Grid className={classes.serif} container wrap="nowrap" alignItems="flex-end">
        <img src={Kagura} width={36} height={37} />
        <div className={classes.kagura}>最初から登録することで、マッチングしやすくなるよ♪</div>
      </Grid>
      <Grid className={classes.serif} container wrap="nowrap" alignItems="flex-end">
        <img src={Sakaki} width={36} height={36} />
        <div className={classes.sakaki}>サブ写真を登録して、スタートダッシュを決めよう！</div>
      </Grid>
    </div>
  )
}

SubPhotosAdvice.propTypes = {
  user: PropTypes.object,
}
