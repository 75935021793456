import Config from './config'

let s3Bucket = 'aill-user-files'
let s3RootDir = 'users'
let s3ChatDir = 'chat-rooms'
let s3FoodReportDir = 'food-reports'

if (process.env.NODE_ENV !== 'production' || Config.stagingEnv) {
  s3Bucket = 'aill-user-files-dev'
}

export default {
  s3Bucket,
  s3RootDir,
  s3ChatDir,
  s3FoodReportDir,
  region: 'ap-northeast-1',
  maxGalleryItemCount: 10,
  colors: {
    angel: '#3C0077',
    devil: '#060179',
    angelBackground: 'rgba(162,105,218,0.44)',
    angelBackground2: 'rgba(208,177,237,0.5)',
    angelBackground3: '#D6BCEF',
    devilBackground: 'rgba(88,155,251,0.5)',
    devilBackground2: 'rgba(171,205,253,0.5)',
    devilBackground3: '#ABCDFD',
    angelSecret: '#8636D6',
    devilSecret: '#006EDF',
    angelSecretBorder: 'rgba(134,54,214,0.6)',
    devilSecretBorder: 'rgba(77,152,255,0.6)',
    leftSpeach: '#414141',
    rightSpeach: '#ffffff',
  },
  maxFriendCountStandard: 10,
  maxFriendCountTrial: 1,
  maxFriendCountSpecialFree: 10,
  software: {
    version: '20250213.1',
    build: '2025021301',
    updated: '2025-02-13T09:20:00+09:00'
  },
  newDisplayPeriod: 7,
  friend_expiration_days: 7,
  lover_expiration_days: 10,
  matching_hour: '12:00:00+09:00',
  // 自己紹介文
  maxToMessageLength: 1000,
  // メッセージ文(トーク)
  maxChatMessageLength: 1000,
  minFirstChatMessageLength: 10,
  // ファイルサイズ上限: 16MB
  maxFileSize: 16 * 1024 * 1024,
  terms: {
    version: '20240729.1',
    updated: '2024-07-29T12:15:00+09:00'
  },
  planTypes: {
    // スタンダードプラン
    STANDARD: 'standard',
    // スタンダードプラン(入会後14日間の無料トライアル期間)
    STANDARD_INITIAL: 'standard_initial',
    // スタンダードプラン(永年無料)
    // 対象1: 2021年10月以前に本登録したユーザー
    // 対象2: モニターユーザー
    STANDARD_FREE: 'standard_free',
    // フリープラン(旧称: トライアルプラン)
    TRIAL: 'trial',
    FREE: 'trial',
    // プレミアムプラン
    PREMIUM: 'premium',
    // 特別フリープラン
    SPECIAL_FREE: 'special_free',
    // スタンダードプラン(全額企業払)
    STANDARD_ORG_PAID: 'standard_org_paid',
    // ベーシックプラン(全額企業払の特別フリープラン)
    BASIC: 'basic',
  },
  // 利用プラン自動移行の開始Hour
  subscriptionUpdateHour: 8,
}

export const PhotoFeatures = Object.freeze({
  // 無表情
  NO_EXPRESSION: 'no_expression',
  // 清潔感がない
  NOT_CLEAN: 'not_clean',
  // 横顔
  FROM_SIDE: 'from_side',
  // 顔が見えない（70%以上が隠れている）
  HIDDEN_FACE: 'hidden_face',
  // 顔が小さい（遠い）
  SMALL_FACE: 'small_face',
  // 画像が荒い
  LOW_QUALITY: 'low_quality',
  // 加工しすぎ
  TOO_PROCESSING: 'too_processing',
  // 写真が暗い
  LOW_BRIGHTNESS: 'low_brightness',
  // 背景が微妙
  BAD_BACKGROUND: 'bad_background',
  // 自撮りの写りが悪い
  BAD_SELFIE: 'bad_selfie',
  // メインにした方が良い
  BETTER_TO_MAIN: 'better_to_main',
  // サブ写真が少ない
  FEW_GALLERY_ITEMS: 'few_gallery_items',
  // もう一枚顔全体が写った写真があれば良い
  FEW_FACES: 'few_faces',
  // その他
  OTHERS: 'others',
})
