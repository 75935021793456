import React, { useEffect } from 'react'
import $ from 'jquery'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.spacing(2),
  },
  sectionTitle: {
    ...theme.styles.title,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  itemCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  qaMark: {
    float: 'left',
    fontWeight: 'bold',
  },
  qaText: {
    marginLeft: theme.spacing(2.66),
  },
  qContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.66),
  },
  naviSampleBoth: {
    borderRadius: 14,
    background: Const.colors.angelBackground,
    padding: theme.spacing(1.66),
    marginTop: theme.spacing(1.66),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
    color: Const.colors.angel,
  },
  naviSampleOneSide: {
    border: `dashed 2px ${Const.colors.angelSecretBorder}`,
    borderRadius: 14,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    marginTop: theme.spacing(1.66),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: Const.colors.angelSecret,
    textAlign: 'center'
  },
  naviSampleBoth2: {
    borderRadius: 14,
    background: Const.colors.devilBackground,
    padding: theme.spacing(1.66),
    marginTop: theme.spacing(1.66),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
    color: Const.colors.devil,
  },
  naviSampleOneSide2: {
    border: `dashed 2px ${Const.colors.devilSecretBorder}`,
    borderRadius: 14,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    marginTop: theme.spacing(1.66),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: Const.colors.devilSecret,
    textAlign: 'center'
  },
  mailAddress: {
    textDecoration: 'underline',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(5.33)
  },
  link: {
    textDecoration: 'underline',
  },
  Message: {
    display: 'inline',
  },
  qaTextTop: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2.66),
  },
}))

const sections = [
  {
    title: '紹介について',
    id: 'matching',
    items: [
      {
        q: '所属会社の人は紹介されますか？',
        a: [
          '社外の方をご紹介します。',
          '（社内の方はご紹介しません）',
          '※グループ会社の方のご紹介有無は、ご利用者本人が選択することができます。',
        ]
      },
      {
        q: '紹介された異性はいつまで紹介画面に表示されますか？',
        a: [
          '紹介画面に7日間表示されます。',
        ]
      },
      {
        q: '紹介された異性が退会した場合、追加の紹介はありますか？',
        a: [
          '紹介された異性が最初に表示されてから24時間以内または、初回紹介ブースト中に退会した場合は、追加の紹介を行います。24時間経過後に紹介された異性が退会した場合は、追加の紹介はございません。',
        ]
      },
      {
        q: '「いいね！」とは何ですか？',
        a: [
          '今日の出逢いにて紹介された異性に対して、コミュニケーションを取っていきたい場合に申請いただく機能です。',
          'お相手からも「いいね！」が送り返されましたら、「友達」としてトークルームが開通いたします。'
        ]
      },
      {
        q: '第一印象はそのまま相手に好感度として表示されるのですか？',
        a: [
          '第一印象はそのまま相手に好感度として表示されません。',
          '会員自身の行動実績や全会員との相対解析により好感度や告白成功確率を感知いたします。'
        ]
      },
      {
        q: '異性に表示される好感度を自分で把握することはできますか？',
        a: [
          '現段階では異性に表示される好感度を自分で把握することはできません。',
          '今後、会員の皆様のご意見をもとに追加すべき機能かを検討させていただく予定です。'
        ]
      },
      {
        q: '「好印象」とは何ですか？',
        a: [
          'お相手の気になるプロフィールに「好印象」（ハート）を押すことであなたの好みをAIが学習します。',
        ]
      },
      {
        q: '異性に表示される「好印象」の数を自分で把握することはできますか？',
        a: [
          '現段階では異性に表示される「好印象」の数を自分で把握することはできません。',
          '今後、会員の皆様のご意見をもとに追加すべき機能かを検討させていただく予定です。'
        ]
      },
      {
        q: '「いいね！」の数に制限はありますか？',
        a: [
          'ご利用プラン別に制限がございますのでご注意ください。',
          'スタンダードプランの場合、友達人数（トーク開通数）が10人を超えると「いいね!」ができなくなります。',
          'その際は友達人数を9人以下にする（どなたかの友達を解消する）必要があります。',
          'フリープランの場合、友達人数は1人までとなります。'
        ]
      },
      {
        q: '「いいね！」受信は「いいね！」画面に表示されますか？',
        a: [
          '「いいね！」受信時は「いいね！」画面の「相手から」ページに7日間反映されます。',
          '7日を過ぎると二度と表示されることはありません。ご注意ください。'
        ]
      },
      {
        q: '「いいね！」受信時、お断りすることはできますか？',
        a: [
          'お断りをすることもできます。',
          'お断りした場合は、その異性の情報を閲覧することができなくなります（今後、今日の出逢いとして紹介もされません）。',
          'よくご検討の上、お決めくださいませ。'
        ]
      },
      {
        q: '初回ブースト期間中に5人にいいね！したにも関わらず、追加の5人が紹介されません。なぜでしょうか？',
        a: [
          '追加紹介は初回ブースト期間終了後にご紹介が反映されます。',
          '初回ブースト終了時間は紹介画面に表示されています。'
        ]
      },
    ]
  },
  {
    title: 'プロフィール公開設定について',
    id: 'profile_public_setting',
    items: [
      {
        q: '自分のプロフィールを非公開にするとどうなりますか？',
        a: [
          '自分のプロフィール編集画面から非公開に設定すると、相手からはその項目が見えなくなります。また、トーク開通後に個別で公開設定を設定することができるので、特定の相手にのみ公開することも可能です。',
          '※国籍が「日本」以外の方は、国籍を非公開にすると相手には「海外」と表示されます。',
        ]
      },
    ]
  },
  {
    title: '会話ナビについて',
    id: 'talk_navi',
    items: [
      {
        q: '狛犬はどういう時に会話（トーク）に介入するのですか？',
        a: [
          '2人のトークが停滞している時、話題に困ってる時、仕事など忙しくトークができない時など狛犬がトーク・会話をアシストします。',
          '※2人のトークの頻度や内容によって狛犬のアシスト数が異なります。',
          '（2人の会話が盛り上がっている場合、会話のアシストは必要最低限になります。）'
        ]
      },
      {
        q: '狛犬からのメッセージに文字の色が違うものがあるのですが、どういう意味ですか？',
        a: [
          '2人が閲覧できるトークと、ご自身のみ閲覧できるトークの2種類があります。'
        ],
        methodNames: ['naviMessageSample']
      },
      {
        q: '狛犬からのアンケート回答は異性に表示されるのですか？',
        a: [
          'そのまま異性に伝わることはございません。普段の会話内容や頻度など総合的に判断して好感度等を算出しています。',
          '同時にアンケート結果は原則、AIの育成のためにも使用しています。'
        ],
      },
      {
        q: '秘密の質問部屋とは何ですか？',
        a: [
          '秘密の質問部屋とは、2人がある条件を満たした時に発現するトークイベントです。',
          'AIのキャラクターが、普段聞きたくても聞けないことを代わりに質問をしていきます。',
          'AIの質問によって、お相手の本当の姿を知る近道になります。'
        ],
      }
    ]
  },
  {
    title: '好感度ナビとは？',
    id: 'fav_navi',
    items: [
      {
        q: '第一印象の情報がそのまま好感度として相手に表示されるのですか？',
        a: [
          '第一印象はそのまま相手に好感度として表示されません。ご注意ください。',
          '会員自身の行動実績や全会員との相対解析により好感度や好感度向上ポイントを感知いたします。'
        ]
      },
      {
        q: '好感度はどうやって算出しているのですか？',
        a: [
          '普段の会話内容や頻度などの情報をもとに、会員自身の行動実績や全会員との相対解析により総合的に判断して好感度を算出しています。'
        ]
      },
      {
        q: '好感度は変動するのですか？',
        a: [
          '好感度はコミュニケーションを重ねるほど随時変動します。',
          '※好感度はサービスユーザー全体の行動データを基に解析されています。'
        ]
      },
      {
        q: '狛犬からのアンケート回答は異性に表示されるのですか？',
        a: [
          'そのまま異性に伝わることはございません。普段の会話内容や頻度など総合的に判断して好感度等を算出しています。',
          '同時にアンケート結果は原則、AIの育成のためにも使用しています。'
        ]
      },
    ]
  },
  {
    title: '退会について',
    id: 'withdrawal',
    items: [
      {
        q: 'Aillを辞めたい時の退会方法を教えてください',
        a: [
          '退会手続きは、Aill goenサービスサイトの「会員ログイン（メンバーズサイト）」から行うことができます。',
          '※退会手続き完了後はログインができなくなります。ご注意ください。'
        ]
      },
      {
        q: '退会をしたら利用中のプランも解約になりますか？',
        a: [
          '退会手続き後、退会と同時にご利用のプランも解約となります。',
        ]
      },
    ]
  },
  {
    title: '料金・お支払い方法について',
    id: 'price',
    items: [
      {
        q: '有料プランの料金はいくらですか？',
        a: [
          '会員登録と1名のメッセージ交換は、男性・女性ともに無料です。2人以上の方とメッセージを交換していただくためには、有料プラン（スタンダードプラン）の購入が必要となります。 月額利用料金の定価は6000円ですが、企業契約によってご利用料金が異なります。',
          '相場は4,000~4,800円となります。企業からのご案内の確認またはお問い合わせください。'
        ]
      },
      {
        q: '入会金や退会金はありますか？',
        a: [
          '入会金や退会金はございません。無料ですので、ご安心ください。'
        ]
      },
      {
        q: '有効期間中に退会となった場合、料金の返金はありますか？',
        a: [
          '有料プラン（スタンダードプラン）の有効期間以内に解約や退会をされた場合、料金の日割り計算や払い戻しはできませんので、ご了承ください。',
          '※強制退会の場合も返金は行っておりません。ご了承ください。'
        ]
      },
      {
        q: '料金の発生と、更新のタイミングはいつですか？',
        a: [
          '有料プラン（スタンダードプラン）を申し込み完了した日に料金が発生します。その後、申込完了日から１ヶ月毎に登録されたクレジットカードの引き落しが更新されます。',
          '※引き落としのタイミングはご利用のクレジットカード会社によって異なります。'
        ]
      },
      {
        q: '有料プランから無料プランに変更した場合、料金はどうなりますか？',
        a: [
          '有料プランから無料プランに変更した時点で、次のクレジットカード引き落としは行われません。有効期間内に無料プランへ変更された場合、料金の日割り計算や払い戻しはできませんので、ご了承ください。',
          '無料プランはお支払い済みの有料プラン期間終了後に適用されます。'
        ]
      },
      {
        q: '有料プランが無料で使えるキャンペーン中に無料プランに変更した場合、料金はどうなりますか？',
        a: [
          `有料プランが無料で使えるキャンペーン期間終了日翌日の午前${Const.subscriptionUpdateHour - 1}時までに無料プランに変更された場合は、有料プランの料金は発生しません。`,
          'キャンペーン期間終了時に選択されているプランが適用されます。',
          `終了日翌日の午前${Const.subscriptionUpdateHour - 1}時以降に無料プランに変更された場合は、初月の有料プランのお支払いが発生しますので、ご注意ください。`
        ]
      },
      {
        q: '有料プランが無料で使えるキャンペーン中に無料プランに変更し、その後キャンペーン中に再度有料プランに変更した場合、料金はどうなりますか？',
        a: [
          `キャンペーン期間終了までは継続して有料プランを無料でご利用いただけます。キャンペーン終了日翌日の午前${Const.subscriptionUpdateHour - 1}時以降、有料プランを選択されている場合は自動更新でお支払いとなります。`
        ]
      },
    ]
  },
  {
    title: 'その他',
    id: 'others',
    items: [
      {
        q: 'AIに個人情報を登録するのですか？',
        a: [
          '入会時に登録いただいた個人情報をAIに登録（情報収集）をすることはございませんのでご安心ください。',
          'AIが収集させていただく情報は入会時に登録いただいた個人情報を除くサービス使用実績です。',
        ]
      },
      {
        q: '会員から迷惑行為があった場合どうすれば良いですか？',
        a: [
          '該当会員のトークページの「違反者報告」からご連絡をお願いします。'
        ]
      },
      {
        q: '友達解消方法について',
        a: [
          '下記の手順にて友達解消ができます。'
        ],
        methodNames: ['howToCancelFriend']
      },
      {
        q: '他SNSでのやり取りは可能ですか？',
        a: [
          '大変申し訳ございませんが、卒業するまでは他SNSでの連絡は利用規約違反となります。',
          '利用規約（第10条 禁止行為）にも記載しておりますので、併せてご確認ください。',
          'Aill goenは福利厚生サービスとして安心安全を最重要項目としております。',
          'Aill goenを利用対象にいただいている全ての企業や独身社員が安心安全に利用できるよう、ご協力お願いいたします。',
          '（Aill goen外での活動におきましては弊社は一切責任を負えなくなります。）',
        ],
      },
      {
        q: '卒業基準は何ですか？',
        a: [
          'Aill goenサービスにて、出逢った異性とお付合いの関係になりましたら卒業となります。'
        ]
      },
      {
        q: '卒業後もサービスを使えるのですか？',
        a: [
          'はい。お付き合いされた異性とトークなどのコミュニケーションツールとしてご利用いただけます。',
          '※お付き合い成立と同時に、他の異性の方は自動で友達解消となりますのでご注意ください。'
        ]
      },
      {
        q: 'プッシュ通知が届きません。どうすれば良いですか？',
        a: [
          '「アプリ左上メニュー > 通知設定」からも各種通知の設定が可能となります。',
          'また、端末やAill goenアプリの設定によって、プッシュ通知が届かない場合があります。端末やアプリの設定をご確認ください。',
        ],
        methodNames: ['howToPushNotification']
      },
      {
        q: '「いいね！」受信時の通知について',
        a: [
          '「トークリクエストが届きました！」：新たに「いいね！」を受信した際の通知',
          '「いいね！受信中」：「いいね！」を受信してから3日間リアクションがなかった場合のリマインド通知（こちらは新着ではございませんので、「いいね！」一覧は更新されません）',
        ],
      },
      {
        q: '有料プランから無料プランに変更しました。いつから無料プランが適用されますか？',
        a: [
          'すでにお支払いが完了している有料プランの期間が終了した時点から無料プランが適用されます。',
          '有料期間内に無料プランへ変更された場合、料金の日割り計算や払い戻しはできませんので、ご注意ください。'
        ],
      },
      {
        q: '機種変更時のデータ移行はどのようにすれば良いですか？',
        a: [
          '新しい端末にAill goenアプリをインストールの上、Aill goenにご登録のメールアドレスとパスワードを入力しログインいただくと移行完了となります。',
          '機種変更やアンインストールにより失われるデータはございません。',
        ],
      },
      {
        q: '「紹介条件の設定」を設定しても希望通りの方が紹介されません。',
        a: [
          '優先してほしい希望をAIに伝える機能となっており、条件に一致しない方は除外になるという機能ではありません。',
          'AIがユーザー様のご希望をふまえ、相性を優先したご紹介をさせていただいております。',
        ],
      },
      {
        q: '以前紹介された方が再度紹介されることはありますか？',
        a: [
          'ご紹介の相性基準を満たしている方が不足している状態の際、以前リアクションをしなかった方を再度ご紹介する場合がございます。',
        ],
      },
      {
        q: 'チャットの送信文を取り消すことは可能ですか？',
        a: [
          'トークルームにて対象の吹き出しを長押ししていただくことで、送信したメッセージを削除できます。相手の画面からも見えません。',
        ],
      },
    ]
  }
]

export default function FAQ (props) {
  const { ...other } = props
  const classes = useStyles()

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const onClickSectionLink = (sectionId) => {
    let sectionTop = $('#' + sectionId).prop('offsetTop')
    let headerHeight = $('header').prop('offsetHeight')
    $('html').animate({ scrollTop: sectionTop - headerHeight })
  }

  const naviMessageSample = (index) => (
    <Grid key={`naviMessageSample${index}`} container alignItems="center" spaceing={16}>
      <Grid item xs={6}>
        <Typography className={classes.naviSampleBoth}>2人が閲覧可能</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.naviSampleOneSide}>ご自身のみが<br />閲覧可能</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.naviSampleBoth2}>2人が閲覧可能</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.naviSampleOneSide2}>ご自身のみが<br />閲覧可能</Typography>
      </Grid>
    </Grid>
  )

  const howToCancelFriend = (index) => (
    <div key={`howToCancelFriend${index}`}>
      <ol>
        <li><Typography>該当会員のトークページを開く</Typography></li>
        <li><Typography>画面右上のメニューをタップ</Typography></li>
        <li><Typography>友達解消をタップ</Typography></li>
      </ol>
      <Typography className={classes.qaText}>
        ※友達解消されますと、今後その異性とのコミュニケーションや情報を閲覧することができなくなります。ご注意ください。
      </Typography>
    </div>
  )

  const howToPushNotification = (index) => (
    <div key={`howToPushNotification${index}`}>
      <Typography className={classes.qaTextTop}>
        【アプリ設定】<br />
      </Typography>
      <ol>
        <li><Typography>アプリ左上メニュー &gt; 通知設定</Typography></li>
        <li><Typography>右側のボタンをタップしON（ブルー）/OFF（グレー）切り替え</Typography></li>
      </ol>
      <Typography className={classes.qaTextTop}>
        【iOS手順】
      </Typography>
      <ol>
        <li><Typography>ホーム画面の「設定」をタップ</Typography></li>
        <li><Typography>設定一覧の「通知」をタップ</Typography></li>
        <li><Typography>「Aill」をタップし、「通知を許可」をONにする</Typography></li>
      </ol>
      <Typography className={classes.qaTextTop}>
        【Android手順】<br />
        ※機種により画面構成が異なる場合がありますのでご了承ください。
      </Typography>
      <ol>
        <li><Typography>ホーム画面またはアプリ一覧の「設定」をタップ</Typography></li>
        <li><Typography>設定一覧の「アプリ」をタップ</Typography></li>
        <li><Typography>アプリ一覧の「Aill」をタップ</Typography></li>
        <li><Typography>Aillの「通知」をタップ</Typography></li>
        <li><Typography>通知を許可するように設定を変更する</Typography></li>
      </ol>
      <Typography className={classes.qaText}>
        ※上記の手順でもプッシュ通知が届かない場合は、お手数をお掛けしますがAillアプリの
        アカウント画面にある「ご意見ご要望」にてご連絡ください。
      </Typography>
    </div>
  )

  return (
    <>
      <AppHeader title="Q&amp;A" backWhite {...other} />
      <div className={classes.root}>
        <ul className="faq_section_links">
          {sections.map((section, index) => (
            <li key={index} onClick={() => onClickSectionLink(section.id)}>
              <Typography><u>{section.title + ' >'}</u></Typography>
            </li>
          ))}
        </ul>
        {sections.map((section, index) => (
          <div key={`section${index}`}>
            <Typography id={section.id} className={classes.sectionTitle}>
              {section.title}
            </Typography>
            <Card className={classes.itemCard}>
              {section.items.map((item, index) => (
                <div key={`section.item${index}`}>
                  <div className={classes.qContainer}>
                    <Typography className={classes.qaMark}>Q.</Typography>
                    <Typography className={classes.qaText}><b>{item.q}</b></Typography>
                  </div>
                  <div>
                    <Typography className={classes.qaMark}>A.</Typography>
                    {item.a.map((text, index) => (
                      <Typography key={`section.item.a${index}`} className={classes.qaText}>
                        {text}
                      </Typography>
                    ))}
                    {item.methodNames && item.methodNames.map(methodName => {
                      const map = {
                        naviMessageSample: naviMessageSample(),
                        howToCancelFriend: howToCancelFriend(),
                        howToPushNotification: howToPushNotification()
                      }
                      return map[methodName]
                    })}
                  </div>
                </div>
              ))}
            </Card>
          </div>
        ))}
      </div>
    </>
  )
}
