import React, { useState, useEffect } from 'react'
import path from 'path'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import Const from 'commons/constant'
import Features from 'commons/features'
import Storage from 'commons/storage'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import {
  isEmpty,
  calculateHourDiff,
  isGlobalNotificationType,
  isProfileIgnoredKeys,
  isProfilePublicFieldName,
  isProfilePrivateFieldName,
  addDate,
  addHours,
} from 'commons/utility'
import FreeTermExtensionChallengeDialog from 'components/free-term-extension-challenge/FreeTermExtensionChallengeDialog'
import FreeTermExtensionChallengeNotifyDialog from 'components/free-term-extension-challenge/FreeTermExtensionChallengeNotifyDialog'
import MovieEventAnnounceDialog from 'components/movie/AnnounceDialog'
import AdvicePhotoDialog from 'components/notifications/AdvicePhotoDialog'
import ApprovePushDialog from 'components/notifications/ApprovePushDialog'
import CooperateDialog from 'components/notifications/CooperateDialog'
import DiscountTermExtentionDialog from 'components/notifications/DiscountTermExtentionDialog'
import DocomoRugbyTicketPresentDialog from 'components/notifications/DocomoRugbyTicketPresentDialog'
import FBAnnounceDialog from 'components/notifications/FBAnnounceDialog'
import FocusPointAdviceDialog from 'components/notifications/FocusPointAdviceDialog'
import FreeTermExtentionDialog from 'components/notifications/FreeTermExtentionDialog'
import MatchingTutorialUpdatedDialog from 'components/notifications/MatchingTutorialUpdatedDialog'
import MonitorUserDialog from 'components/notifications/MonitorUserDialog'
import NewCampaignDialog from 'components/notifications/NewCampaignDialog'
import NewFeatureDialog from 'components/notifications/NewFeatureDialog'
import NewUpdateDialog from 'components/notifications/NewUpdateDialog'
import PhotoRateNotifyDialog from 'components/notifications/PhotoRateNotifyDialog'
import SmsAuthSettingRequestDialog from 'components/notifications/SmsAuthSettingRequestDialog'
import BaseComponent from 'components/parts/BaseComponent'
import ChatMemoTutorialDialog from 'components/parts/ChatMemoTutorialDialog'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog'
import LoverEntryDialog from 'components/parts/LoverEntryDialog'
import NewOrganizationsDialog from 'components/parts/NewOrganizationsDialog'
import NewProfileItemDialog from 'components/parts/NewProfileItemDialog'
import NotifyLoveReceiveDialog from 'components/parts/NotifyLoveReceiveDialog'
import RatingUpDialog from 'components/parts/RatingUpDialog'
import RemindRequestDialog from 'components/parts/RemindRequestDialog'
import FreePlanSuggestDialog from 'components/plan/FreePlanSuggestDialog'
import SelfIntroductionNotifyDialog from 'components/self-introduction/SelfIntroductionNotifyDialog'
import SpecialOfferDialog from 'components/special-offer/SpecialOfferDialog'
import ValentineEventAnnounceDialog from 'components/valentine-event/AnnounceDialog'
import ValentineEventChallengeDialog from 'components/valentine-event/ValentineEventChallengeDialog'
import WinterEventAnnounceDialog from 'components/winter-event/AnnounceDialog'
import WinterEventChallengeDialog from 'components/winter-event/WinterEventChallengeDialog'
import XmasCampaignAnnounceDialog from 'components/xmas-campaign/AnnounceDialog'
import foodReportPopUpImg from 'images/Komainu/foodReportPopUpImg.png'
import NaviUpdateImg from 'images/Komainu/naviUpdateImg.png'
import PhotoTutorialUpdateImg from 'images/Komainu/photoTutorialUpdateImg.png'

const showScreen = ['Home', 'FriendRequest', 'Friends', 'Landing']

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  info: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    ...theme.styles.messageColor,
  },
  message: {
    textAlign: 'left',
    marginBottom: theme.spacing(3),
    ...theme.styles.messageColor,
  },
  twoButtons: theme.styles.dialogActions2Buttons,
  threeButtons: theme.styles.dialogActions3Buttons,
  faceAvatar: {
    marginRight: theme.spacing(1),
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginBottom: theme.spacing(4),
  },
  speachA: {
    ...theme.styles.naviSpeach(Const.colors.angelBackground),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 'calc(100% - 52px)',
    maxWidth: 300,
    textAlign: 'left',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    '& p': {
      fontSize: 12,
    }
  },
  title: {
    '& h2': {
      ...theme.styles.messageColor,
    }
  },
  naviText: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  campaignNaviText: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  naviSubText: {
    fontSize: 12,
  },
  campaignNaviSubText: {
    fontSize: 10,
  },
}))

export default function NotifyDialog (props) {
  const { user, master, subscription, tutorialReadStates, ...other } = props
  const classes = useStyles()
  let cable = null
  let channel = null
  let apply_lover_friends = []

  const [myDialogs, setMyDialogs] = useState([])
  // NotifyLoveReceiveDialog
  const [applyLoverFriends, setApplyLoverFriends] = useState([])
  // RemindRequestDialog
  const [stateIndex, setStateIndex] = useState(false)
  const [stateFriend, setStateFriend] = useState([])
  const [openRemindRequestDialog, setOpenRemindRequestDialog] = useState(false)
  // NewProfileItemDialog
  const [openNewProfileItemDialog, setOpenNewProfileItemDialog] = useState(false)
  const [newProfileLabels, setNewProfileLabels] = useState('')
  const [openAutoPhotoAdjustmentDialog, setOpenAutoPhotoAdjustmentDialog] = useState(false)
  const [openFixProfileItemDialog, setOpenFixProfileItemDialog] = useState(false)
  const [fixProfileText, setFixProfileText] = useState('')
  const [newProfileItemDialogId, setNewProfileItemDialogId] = useState(null)
  // RatingUpDialog
  const [openRatingUpDialog, setOpenRatingUpDialog] = useState(false)
  const [ratingUpNotifications, setRatingUpNotifications] = useState([])
  const [apply_friend_count, setApply_friend_count] = useState(0)
  const [apply_lover_count, setApply_lover_count] = useState(0)
  const [approve_friend_count, setApprove_friend_count] = useState(0)
  // Dialog
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(null)
  // ImportantConfirmDialog
  const [openApproveLoverDialog, setOpenApproveLoverDialog] = useState(false)
  // LoverEntryDialog
  const [openLoverEntryDialog, setOpenLoverEntryDialog] = useState(false)
  const [openLoverRejectDialog, setOpenLoverRejectDialog] = useState(false)
  // NewOrganizationsDialog
  // 廃止予定
  const [openOrganizationsDialog, setOpenOrganizationsDialog] = useState(false)
  const [newOrgNotifications, setNewOrgNotifications] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [newCompanyNames, setNewCompanyNames] = useState([])
  const [newCompanyNotifications, setNewCompanyNotifications] = useState([])
  const [openNewCompaniesDialog, setOpenNewCompaniesDialog] = useState(false)
  // MatchingTutorialUpdatedDialog
  const [openMatchingTutorialUpdatedDialog, setOpenMatchingTutorialUpdatedDialog] = useState(false)
  // NewFeatureDialog
  const [openNewFeatureDialog, setOpenNewFeatureDialog] = useState(false)
  const [newFeatureDialogId, setNewFeatureDialogId] = useState(null)
  // SelfIntroductionNotifyDialog
  const [openSelfIntroductionTemplateDialog, setOpenSelfIntroductionTemplateDialog] = useState(false)
  // PhotoRateNotifyDialog
  const [openPhotoRateNotifyDialog, setOpenPhotoRateNotifyDialog] = useState(false)
  // ChatMemoTutorialDialog
  const [openChatMemoTutorialDialog, setOpenChatMemoTutorialDialog] = useState(false)
  // Matching202201
  const [openUpdateNaviDialog, setOpenUpdateNaviDialog] = useState(false)
  // PhotoTutorial202202
  const [openPhotoTutorialUpdateDialog, setOpenPhotoTutorialUpdateDialog] = useState(false)
  // FoodReportEvent202203
  const [openFoodReportEventDialog, setOpenFoodReportEventDialog] = useState(false)
  // FreeTermExtentionDialog
  const [openFreeTermExtentionDialog, setOpenFreeTermExtentionDialog] = useState(false)
  const [freeTermExtentionData, setFreeTermExtentionData] = useState(null)
  // FreePlanDialog
  const [openFreePlanDialog, setOpenFreePlanDialog] = useState(false)
  const [freePlanData, setFreePlanData] = useState(null)
  // DiscountTermExtentionDialog
  const [openDiscountTermExtentionDialog, setOpenDiscountTermExtentionDialog] = useState(false)
  const [discountTermExtentionData, setDiscountTermExtentionData] = useState(null)
  // CooperateDialog
  const [openPaidUserHearingDialog, setOpenPaidUserHearingDialog] = useState(false)
  const [paidUserHearingParams, setPaidUserHearingParams] = useState(null)
  const [openCoupleHearingDialog, setOpenCoupleHearingDialog] = useState(false)
  const [coupleHearingParams, setCoupleHearingParams] = useState(null)
  // MonitorUserDialog
  const [openMonitorUserDialog, setOpenMonitorUserDialog] = useState(false)
  const [monitorUserParams, setMonitorUserParams] = useState(null)
  const [openMonitorUserHearingDialog, setOpenMonitorUserHearingDialog] = useState(false)
  const [monitorUserHearingParams, setMonitorUserHearingParams] = useState(null)
  // MovieEventAnnounceDialog
  const [openMovieEventPreDialog, setOpenMovieEventPreDialog] = useState(false)
  const [openMovieEventDialog, setOpenMovieEventDialog] = useState(false)
  const [movieEventPreParams, setMovieEventPreParams] = useState(null)
  const [movieEventParams, setMovieEventParams] = useState(null)
  // FreeTermExtensionChallengeDialog
  const [openFreeTermExtensionChallengeDialog, setOpenFreeTermExtensionChallengeDialog] = useState(false)
  const [friendApplyMission, setFriendApplyMission] = useState(null)
  const [chatOpenMission, setChatOpenMission] = useState(null)
  // FreeTermExtensionChallengeNotifyDialog
  const [openFreeTermExtensionChallengeNotifyDialog, setOpenFreeTermExtensionChallengeNotifyDialog] = useState(false)
  // WinterEventAnnounceDialog
  const [openWinterEventAnnounceDialog, setOpenWinterEventAnnounceDialog] = useState(false)
  // WinterEventChallengeDialog
  const [openWinterEventChallengeDialog, setOpenWinterEventChallengeDialog] = useState(false)
  const [winterEventMissions, setWinterEventMissions] = useState([])
  // ValentineEventAnnounceDialog
  const [openValentineEventAnnounceDialog, setOpenValentineEventAnnounceDialog] = useState(false)
  // ValentineEventChallengeDialog
  const [openValentineEventChallengeDialog, setOpenValentineEventChallengeDialog] = useState(false)
  const [valentineEventMissions, setValentineEventMissions] = useState([])
  // XmasCampaignDialog
  const [openXmasCampaignAnnounceDialog, setOpenXmasCampaignAnnounceDialog] = useState(false)
  const [campaignType, setCampaignType] = useState(null)
  const [campaignCode, setCampaignCode] = useState(null)
  const [notificationForXmasCampaignAnnounceDialog, setNotificationForXmasCampaignAnnounceDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [notificationForConfirmDialog, setNotificationForConfirmDialog] = useState(null)
  // FBAnnounceDialog
  const [openFBAnnounceDialog, setOpenFBAnnounceDialog] = useState(false)
  const [notificationForFBAnnounceDialog, setNotificationForFBAnnounceDialog] = useState(null)
  const [openFBNewAnnounceDialog, setOpenFBNewAnnounceDialog] = useState(false)
  const [notificationForFBNewAnnounceDialog, setNotificationForFBNewAnnounceDialog] = useState(null)
  // SpecialOfferDialog
  const [openSpecialOfferDialog, setOpenSpecialOfferDialog] = useState(false)
  const [specialOfferNotification, setSpecialOfferNotification] = useState(null)
  // DocomoRugbyTicketPresentDialog
  const [openDocomoRugbyTicketPresentDialog, setOpenDocomoRugbyTicketPresentDialog] = useState(false)
  const [docomoRugbyTicketPresentNotification, setDocomoRugbyTicketPresentNotification] = useState(null)
  // FocusPointAdviceDialog
  const [openFocusPointAdviceDialog, setOpenFocusPointAdviceDialog] = useState(false)
  const [focusPointAdviceNum, setFocusPointAdviceNum] = useState(0)
  const [openSmsAuthSettingRequestDialog, setOpenSmsAuthSettingRequestDialog] = useState(false)
  const [smsAuthSettingRequestCanSkip, setSmsAuthSettingRequestCanSkip] = useState(null)
  const [smsAuthSettingRequestLimit, setSmsAuthSettingRequestLimit] = useState(null)
  // AdviceForPhotosDialog
  const [openAdvicePhotoDialog, setOpenAdvicePhotoDialog] = useState(false)
  // ApprovePushDialog
  const [openApprovePushDialog, setOpenApprovePushDialog] = useState(false)

  useEffect(() => {
    openEntriesDialog(props.entries)
  }, [props.entries])

  useEffect(() => {
    openApplyLoverFriends(props.friends)
    openRemindFriendRequestDialog(props.friends)
    openAdviceDialog(props.friends)
  }, [props.friends])

  useEffect(() => {
    if (!props.notifications) { return }
    openFavRatingUp(props.notifications)
    openNewOrganization(props.notifications)
    openNewCompanies(props.notifications)
    openXmasCampaign(props.notifications)
    openContactNotifications(props.notifications)
    openFBAnnounceNotifications(props.notifications)
    openSpecialOfferNotifications(props.notifications)
    openRugbyTicketPresent20250208(props.notifications)
  }, [props.notifications])

  useEffect(() => {
    // プロフィール項目追加のお知らせ：マスター管理されている項目
    checkIsOpenNewProfileItemDialog()
    // 本日の出逢いチュートリアル更新のお知らせ
    checkIsOpenMatchingTutorialUpdatedDialog()
    // 新機能追加のお知らせ：Push通知の個別設定、紹介条件設定
    checkIsOpenNewFeature()
    // 自己紹介文章テンプレ機能のお知らせ
    checkIsSelfIntroductionLength()
    // 写真評価が良くない場合の写真変更依頼
    checkIsPhotoRate()
    // お相手メモ機能リリースのお知らせ
    showChatMemoTutorialDialog()
    // 更新のお知らせ機能：紹介ナビの更新、チュートリアルに「異性に好印象な写真」を撮る方法追加
    checkIsNewUpdate()
    // 新規キャンペーンのお知らせ：JR西タイアップイベント
    checkIsNewCampaign()
    // プロフィール項目追加のお知らせ：マスター管理ではない項目（user.allow_auto_photo_adjustment）
    checkIsOpenAutoPhotoAdjustmentDialog()
    // バックエンドから要表示のお知らせを取得
    getMyDialogs()

    return () => {
      if (channel) {
        channel.unsubscribe()
        channel = null
      }
      if (cable) {
        cable.disconnect()
        cable = null
      }
    }
  }, [])

  // RemindRequestDialog
  const openEntriesDialog = async (entries) => {
    if (!entries) return
    if (entries.sent.length === 0 && entries.received.length === 0) return

    let friendCount = 0
    let loverCount = 0
    let approveFriendCount = 0
    let approveLover = null

    if (entries.sent != null && entries.sent.length > 0) {
      entries.sent.forEach(value => {
        if (value.type === 'approve_friend') {
          if (value.src_user_id !== props.userId) {
            console.log('申請したのが相手')
          } else {
            console.log('申請したのが自分')
          }
          if (value.dest_user_id !== props.userId) {
            console.log('受理したのが相手')
            approveFriendCount++
          } else {
            console.log('受理したのが自分')
          }
        }
        if (value.type === 'approve_lover') {
          // 自分がお付き合い承認した
          approveLover = value
        }
      })
      if (approveLover != null) {
        loadLover(approveLover.dest_user_id)
        return
      }
    }

    if (entries.received != null && entries.received.length > 0) {
      entries.received.forEach(value => {
        if (value.type === 'apply_friend') {
          friendCount++
        }
        if (value.type === 'apply_lover') {
          loverCount++
        }
        if (value.type === 'approve_friend') {
          if (value.src_user_id !== props.userId) {
            console.log('申請したのが相手')
          } else {
            console.log('申請したのが自分')
          }
          if (value.dest_user_id !== props.userId) {
            console.log('受理したのが相手')
            approveFriendCount++
          } else {
            console.log('受理したのが自分')
          }
        }
        if (value.type === 'approve_lover') {
          approveLover = value
        }
        return null
      })
      if (approveLover != null) {
        loadLover(approveLover.src_user_id)
        return
      }
    }

    if (friendCount > 0) {
      const dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'Info.ApprovePush')
      if (!dialogDisyplayed) {
        setOpenApprovePushDialog(true)
        return
      }
    }

    let isOpen = open
    if (friendCount > 0 || loverCount > 0 || approveFriendCount > 0) {
      isOpen = true
    }
    setApply_friend_count(friendCount)
    setApply_lover_count(loverCount)
    setApprove_friend_count(approveFriendCount)
    setOpen(isOpen)
  }

  const loadLover = async () => {
    try {
      await BaseComponent.loadFriends(props)
      props.setScreen('Home')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // RemindRequestDialog
  const handleApproveLoverNext = (index) => {
    props.setFriend(applyLoverFriends[index])
    apply_lover_friends = applyLoverFriends
    apply_lover_friends[index].open = false
    setApplyLoverFriends(apply_lover_friends)
    setOpenApproveLoverDialog(true)
    setStateIndex(index)
    setStateFriend(apply_lover_friends[index])
  }

  const handleApproveLoverHold = (index) => {
    props.setFriend(applyLoverFriends[index])
    apply_lover_friends = applyLoverFriends
    apply_lover_friends[index].open = false
    setApplyLoverFriends(apply_lover_friends)
    let applyLoverHoldTimes = Storage.applyLoverHoldTimes.value || {}
    let holdFriend = { [apply_lover_friends[index].id]: new Date() }
    Object.assign(applyLoverHoldTimes, holdFriend)
    Storage.applyLoverHoldTimes.value = applyLoverHoldTimes
  }

  // RemindRequestDialog
  const openRemindFriendRequestDialog = (friends) => {
    if (!friends) return
    if (!showScreen.includes(path.basename(props.screen))) return

    let lastNotifiedFriends = Storage.notifiedRemindFriendRequests.value
    let thisNotifiedFriends = []
    let open = false
    friends.forEach(friend => {
      if (friend.type === 'friend_receiving') {
        let today = new Date()
        let entryDay = new Date(friend.entry_applied_at)
        entryDay.setDate(entryDay.getDate() + Const.friend_expiration_days)
        let msDiff = entryDay.getTime() - today.getTime()
        let daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24))
        ++daysDiff
        // 残り３日以内
        if (daysDiff <= 3) {
          if (lastNotifiedFriends && lastNotifiedFriends.indexOf) {
            if (lastNotifiedFriends.indexOf(friend.id) === -1) {
              open = true
            }
          } else {
            open = true
          }
          thisNotifiedFriends.push(friend.id)
        }
      }
    })
    Storage.notifiedRemindFriendRequests.value = thisNotifiedFriends
    setOpenRemindRequestDialog(open)
  }

  const handleCloseRemindRequestDialog = () => {
    setOpenRemindRequestDialog(false)
    props.setScreen('FriendRequest')
  }

  const openAdviceDialog = async (friends) => {
    if (!user) { return }
    if (user.lover_with_someone) { return }
    if (!friends) { return }
    try {
      const count = friends.filter(f => f.type === 'friend').length
      const loginDate = new Date(user.first_login_date)
      const today = new Date()
      let dialogDisyplayed
      const res = await Api.getEntries()
      // 初回ログインから48時間経過かつトーク未開通
      if (count === 0 && addHours(loginDate, 48) < today) {
        dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'Info.FocusPointAdvice.1')
        if (dialogDisyplayed) { return }
        setFocusPointAdviceNum(1)
        setOpenFocusPointAdviceDialog(true)
      // 初回ログインから7日経過かつトーク開通1以下
      } else if (count <= 1 && addDate(loginDate, 7) < today) {
        dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'Info.FocusPointAdvice.2')
        if (dialogDisyplayed) { return }
        setFocusPointAdviceNum(2)
        setOpenFocusPointAdviceDialog(true)
      // いいねを15回送っていて、開通をしていない場合
      } else if (res.sent.length >= 15 && count === 0) {
        dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'Info.FocusPointAdvice.3')
        if (dialogDisyplayed) { return }
        setFocusPointAdviceNum(3)
        setOpenFocusPointAdviceDialog(true)
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // NewProfileItemDialog
  const checkIsOpenAutoPhotoAdjustmentDialog = async () => {
    if (!user) { return }
    if (user.allow_auto_photo_adjustment) { return }
    if (new Date(Features.AllowAutoPhotoAdjustment.releaseDate) < new Date(user.created_at)) { return }
    const dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'NewProfileItem.allow_auto_photo_adjustment')
    setOpenAutoPhotoAdjustmentDialog(!dialogDisyplayed)
  }

  // プロフィール編集画面での空の項目
  const isEmptyProfileItem = (key) => {
    if (isProfileIgnoredKeys(key)) { return }
    if (!isProfilePublicFieldName(key) && !isProfilePrivateFieldName(key)) { return }
    if (key === 'to_message') { return }
    return isEmpty(user[key])
  }

  const checkIsOpenNewProfileItemDialog = async () => {
    if (!user || !master) { return }
    const profileMaster = master.profile
    const emptyKeys = Object.keys(profileMaster).filter(key => isEmptyProfileItem(key))
    // 空の項目がある場合表示
    if (emptyKeys.length > 0) {
      const lastDate = Storage.lastNewProfileItemDialogDate.value
      if (lastDate) {
        const today = new Date()
        const hourDiff = calculateHourDiff(new Date(lastDate), today)
        // 前回から1週間経過していなければ表示しない
        if (hourDiff < 168) { return }
      }
      const labels = emptyKeys.map(key => profileMaster[key].title)
      setOpenNewProfileItemDialog(true)
      setNewProfileLabels(labels.join(', '))
      return
    }
    // 喫煙状況に「喫煙 電子たばこ」追加, 相手の喫煙に関してに「非喫煙〜喫煙(電子たばこ)まで良い」追加
    let dialogDisyplayed = false
    if (new Date(Features.NewProfileValue202308.releaseDate) < new Date(user.created_at)) { return }
    dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'NewProfileValue202308')
    if (!dialogDisyplayed) {
      setOpenFixProfileItemDialog(!dialogDisyplayed)
      setFixProfileText(Features.NewProfileValue202308.text)
      setNewProfileItemDialogId('NewProfileValue202308')
      return
    }
    // 「希望する将来の働き方」の項目追加変更, 「勤務地の柔軟性」追加
    if (new Date(Features.NewProfileValue202408.releaseDate) < new Date(user.created_at)) { return }
    dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'NewProfileValue202408')
    if (!dialogDisyplayed) {
      setOpenFixProfileItemDialog(!dialogDisyplayed)
      setFixProfileText(Features.NewProfileValue202408.text)
      setNewProfileItemDialogId('NewProfileValue202408')
      return
    }
    // 勤め先の業種に「製造・印刷」追加
    if (new Date(Features.FixProfileItem202301.releaseDate) < new Date(user.created_at)) { return }
    dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'FixProfileItem202301')
    if (!dialogDisyplayed) {
      setOpenFixProfileItemDialog(!dialogDisyplayed)
      setFixProfileText(Features.FixProfileItem202301.text)
      setNewProfileItemDialogId('FixProfileItem202301')
      return
    }
  }

  const handleInputNewProfileItemDialog = () => {
    Storage.lastNewProfileItemDialogDate.value = new Date()
    setOpenNewProfileItemDialog(false)
    props.setScreen('ProfileEdit')
  }

  const handleCloseNewProfileItemDialog = () => {
    Storage.lastNewProfileItemDialogDate.value = new Date()
    setOpenNewProfileItemDialog(false)
  }

  const handleInputAutoPhotoAdjustmentDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'NewProfileItem.allow_auto_photo_adjustment')
    setOpenAutoPhotoAdjustmentDialog(false)
    props.setScreen('ProfileEdit')
  }

  const handleCloseAutoPhotoAdjustmentDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'NewProfileItem.allow_auto_photo_adjustment')
    setOpenAutoPhotoAdjustmentDialog(false)
  }

  const handleCloseFixProfileItemDialog = (id) => {
    BaseComponent.createReadStateDialogDisplayed(props, id)
    setOpenFixProfileItemDialog(false)
  }

  const openContactNotifications = (notifications) => {
    // 個別お知らせを抽出
    const notifs = notifications.filter(n => n.notification_type === 'contact' && !n.already_read)
    if (notifs.length === 0) { return }
    const oldest = notifs[notifs.length - 1]
    setOpenConfirmDialog(true)
    setNotificationForConfirmDialog(oldest)
  }

  // Facebook連携機能関連
  const openFBAnnounceNotifications = (notifications) => {
    // 未読のお知らせを抽出
    // TODO 全体お知らせの既読判定
    const notifs = notifications.filter(n => !n.already_read)
    // 期限切れ: 個別お知らせ (Notification)
    let notif = notifs.filter(n => n.notification_type === 'fb_connect_expired')
    setOpenFBAnnounceDialog(notif.length === 0 ? false : true)
    setNotificationForFBAnnounceDialog(notif[0])

    // 機能追加: 全体お知らせ (GlobalNotification)
    notif = notifs.filter(n => n.notification_type === 'new_feature_fb_connect')
    setOpenFBNewAnnounceDialog(notif.length === 0 ? false : true)
    setNotificationForFBNewAnnounceDialog(notif[0])
  }

  // スペシャルオファー関連
  const openSpecialOfferNotifications = (notifications) => {
    const notifs = notifications.filter(n => !n.already_read)
    const notif = notifs.find(n => n.notification_type === 'special_offer')
    setOpenSpecialOfferDialog(!!notif)
    setSpecialOfferNotification(notif)
  }

  // スペシャルオファー関連
  const openRugbyTicketPresent20250208 = (notifications) => {
    const notifs = notifications.filter(n => !n.already_read)
    const notif = notifs.find(n => n.notification_type === 'rugby_ticket_present_20250208')
    setOpenDocomoRugbyTicketPresentDialog(!!notif)
    setDocomoRugbyTicketPresentNotification(notif)
  }

  // RatingUpDialog, Dialog
  const openFavRatingUp = (notifications) => {
    // 好感度UPのお知らせを抽出
    const notifs = notifications.filter(n => {
      if (n.already_read) { return false }
      if (n.notification_type !== 'fav_rating_up') { return false }
      const friend = props.friends.find(f => f.id === n.friend_user_id)
      return !!friend
    })
    setRatingUpNotifications(notifs)
    setOpenRatingUpDialog(notifs.length > 0)
  }

  const closeRatingUpDialog = async () => {
    try {
      ratingUpNotifications.map(async (n) => await BaseComponent.updateAlreadyRead(props, n))
      setOpenRatingUpDialog(false)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      BaseComponent.loadNotifications(props)
    }
  }

  const handleClose = async () => {
    if (approve_friend_count > 0) {
      props.setScreen('Friends')
    }
    else if (apply_friend_count > 0) {
      props.setScreen('FriendRequest')
    }
    setOpen(false)
    setMessage(null)
    setApply_friend_count(0)
    setApply_lover_count(0)
    setApprove_friend_count(0)
  }

  // LoverEntryDialog
  const openApplyLoverFriends = (friends) => {
    if (!friends) return
    if (!showScreen.includes(path.basename(props.screen))) return

    apply_lover_friends = []
    let lastApplyLoverHoldTimes = Storage.applyLoverHoldTimes.value
    let thisApplyLoverHoldTimes = {}
    friends.forEach(friend => {
      if (friend.type === 'lover_receiving') {
        let hold = false
        if (lastApplyLoverHoldTimes) {
          let hold_time = lastApplyLoverHoldTimes[[friend.id]]
          if (hold_time) {
            let hourDiff = calculateHourDiff(new Date(hold_time), new Date())
            if (hourDiff < 24) {
              hold = true
              let holdFriend = { [friend.id]: lastApplyLoverHoldTimes[[friend.id]] }
              Object.assign(thisApplyLoverHoldTimes, holdFriend)
            }
          }
        }
        friend.open = hold ? false : true
        apply_lover_friends.push(friend)
      }
    })
    Storage.applyLoverHoldTimes.value = thisApplyLoverHoldTimes
    setApplyLoverFriends(apply_lover_friends)
  }

  const handleApproveLoverDialogHold = () => {
    apply_lover_friends = applyLoverFriends
    let applyLoverHoldTimes = Storage.applyLoverHoldTimes.value || {}
    let holdFriend = { [apply_lover_friends[stateIndex].id]: new Date() }
    Object.assign(applyLoverHoldTimes, holdFriend)
    Storage.applyLoverHoldTimes.value = applyLoverHoldTimes
    apply_lover_friends[stateIndex].open = false
    setApplyLoverFriends(apply_lover_friends)
    setOpenApproveLoverDialog(false)
    props.setFriend(apply_lover_friends[stateIndex])
  }

  // LoverEntryDialog
  const handleSentLoverEntry = async (inputMessage) => {
    if (inputMessage) {
      await sendApproveLoverMessage(inputMessage)
    }
    setOpenApproveLoverDialog(false)
    setOpenLoverEntryDialog(false)
  }

  const handleSentLoverReject = () => {
    setOpenApproveLoverDialog(false)
    setOpenLoverRejectDialog(false)
    props.setScreen('/')
  }

  const sendApproveLoverMessage = async (message) => {
    const fri = props.friends.find(friend => (friend.id === stateFriend.id))
    const roomId = fri.chat_room_id
    props.setLoading(true)
    try {
      await Api.sendChatMessage(roomId, {
        message,
        message_type: 'approve_lover',
      })
      props.setScreen('/')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  // NewOrganizationsDialog
  // 廃止予定
  const openNewOrganization = async (notifications) => {
    const newOrgNotifs = notifications.filter(notif => {
      return notif.notification_type === 'organization'
    })
    if (0 < newOrgNotifs.length) {
      try {
        const orgs = await Api.getOrganizations()
        const orgNames = []
        newOrgNotifs.forEach(notif => {
          notif.organization_ids.forEach(org_id => {
            let org = orgs.filter(org => (org.id === org_id))
            if (org[0]) {
              orgNames.push(org[0].canonical_name)
            }
          })
        })
        if (orgNames.length > 0) {
          setNewOrgNotifications(newOrgNotifs)
          setOrganizations(orgNames)
          setOpenOrganizationsDialog(true)
        } else {
          setNewOrgNotifications([])
          setOrganizations([])
          setOpenOrganizationsDialog(false)
        }
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      }
    } else {
      setNewOrgNotifications([])
      setOrganizations([])
      setOpenOrganizationsDialog(false)
    }
  }

  // 廃止予定
  const handleMoveOrganizations = async () => {
    await updateAlreadyReadGlobal(newOrgNotifications)
    setNewOrgNotifications([])
    setOrganizations([])
    setOpenOrganizationsDialog(false)
    window.open(Config.memberCorporationsUrl, 'aill-member-corporations')
  }

  // 廃止予定
  const handleCloseNewOrganizationsDialog = async () => {
    await updateAlreadyReadGlobal(newOrgNotifications)
    setNewOrgNotifications([])
    setOrganizations([])
    setOpenOrganizationsDialog(false)
  }

  // 参加企業追加のお知らせ
  const openNewCompanies = (notifications) => {
    const companyNotifications = notifications.filter(notif => {
      return notif.notification_type == 'new_companies' && notif.body
    })
    const companyNames = newCompanyNotifications.flatMap(notif => notif.body.split(','))
    setNewCompanyNames(companyNames)
    setNewCompanyNotifications(companyNotifications)
    setOpenNewCompaniesDialog(0 < newCompanyNames.length)
  }

  const onCloseNewCompaniesDialog = async () => {
    await updateAlreadyReadGlobal(newCompanyNotifications)
    setNewCompanyNames([])
    setNewCompanyNotifications([])
    setOpenNewCompaniesDialog(false)
  }

  const openXmasCampaign = (notifications) => {
    const notification = notifications.find(n => {
      if (n.already_read) { return false }
      if (n.notification_type !== 'campaign_started') { return false }
      return (n.campaign_type === 'xmas' || n.campaign_type === 'xmas_for_free')
    })
    setOpenXmasCampaignAnnounceDialog(!!notification)
    setNotificationForXmasCampaignAnnounceDialog(notificationForXmasCampaignAnnounceDialog)
    setCampaignType(notification?.campaign_type)
    setCampaignCode(notification?.campaign_code)
  }

  const goToOrganizations = async () => {
    await onCloseNewCompaniesDialog()
    window.open(Config.memberCorporationsUrl, 'aill-member-corporations')
  }

  const updateAlreadyReadGlobal = async (notifications) => {
    let readIds = []
    notifications.forEach(notif => {
      if (isGlobalNotificationType(notif.notification_type)) {
        readIds.push(notif.id)
      }
    })
    try {
      let params = {
        target: 'GlobalNotification',
        read_ids: readIds,
      }
      await Api.createReadState(params)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // MatchingTutorialUpdatedDialog
  const checkIsOpenMatchingTutorialUpdatedDialog = async () => {
    if (!user) { return }
    // リリースがユーザーの登録日以前の場合表示しない
    if (new Date(Features.MatchingTutorial202111.releaseDate) < new Date(user.created_at)) { return }
    const dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'Update.MatchingTutorial202111')
    setOpenMatchingTutorialUpdatedDialog(!dialogDisyplayed)
  }

  const closeMatchingTutorialUpdatedDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'Update.MatchingTutorial202111')
    setOpenMatchingTutorialUpdatedDialog(false)
  }

  // NewFeatureDialog
  const checkIsOpenNewFeature = async () => {
    if (!user) { return }
    // TODO features.jsを参照している関数をまとめたい
    const ids = [
      'MatchingFilter',
      'PushNotificationUserConfig',
      'FixMatchingFilter202303',
      'FixProfileNationality202308',
      'MessageReply',
      'InterestedThings',
      'MessagePartCopy',
    ]
    for (const id of ids) {
      const feature = Features[id]
      // リリースがユーザーの登録日以前の機能は新規機能として表示しない
      if (new Date(feature.releaseDate) < new Date(user.created_at)) { continue }
      const dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, `NewFeature.${id}`)
      if (dialogDisyplayed) { continue }
      setNewFeatureDialogId(id)
      setOpenNewFeatureDialog(true)
      break
    }
  }

  const handleNewFeatureClose = async (id) => {
    await BaseComponent.createReadStateDialogDisplayed(props, `NewFeature.${id}`)
    setOpenNewFeatureDialog(false)
  }

  const handleNewFeatureConfirm = async (id) => {
    await handleNewFeatureClose(id)
    switch (id) {
      case 'MatchingFilter':
      case 'FixMatchingFilter202303':
        props.setScreen('MatchingFilter')
        break
      case 'PushNotificationUserConfig':
        props.setScreen('MyNotifications')
        break
      case 'FixProfileNationality202308':
        props.setScreen('ProfileEdit')
        break
      case 'InterestedThings':
        props.setScreen('MyProfile')
        break
      default:
        break
    }
  }

  // SelfIntroductionNotifyDialog
  const checkIsSelfIntroductionLength = async () => {
    if (!user) { return }
    if (user.sex !== 'male') { return }
    if (user.to_message && 33 < user.to_message.length) { return }
    const dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'NewFeature.SelfIntroductionTemplate')
    const today = new Date()
    const lastDate = Storage.selfIntroductionTemplateDialogDate.value || false
    if (dialogDisyplayed) {
      if (lastDate) {
        const hourDiff = calculateHourDiff(new Date(lastDate), today)
        if (hourDiff < 24) { return }
      }
    }
    setOpenSelfIntroductionTemplateDialog(true)
    Storage.selfIntroductionTemplateDialogDate.value = today
  }

  const handleSelfIntroductionConfirm = (confirm) => {
    if (confirm) {
      BaseComponent.createReadStateDialogDisplayed(props, 'NewFeature.SelfIntroductionTemplate')
      props.setScreen('MessageEdit')
    }
    setOpenSelfIntroductionTemplateDialog(false)
  }

  // PhotoRateNotifyDialog
  const checkIsPhotoRate = async () => {
    if (!user) { return }
    if (user.photo_rating === 'good') { return }
    const today = new Date()
    const lastDate = Storage.photoRateNotifyDialogDate.value || false
    if (lastDate) {
      const hourDiff = calculateHourDiff(new Date(lastDate), today)
      if (hourDiff < 24) { return }
    }
    setOpenPhotoRateNotifyDialog(true)
    Storage.photoRateNotifyDialogDate.value = today
  }

  const handlePhotoRateConfirm = (confirm) => {
    if (confirm) { props.setScreen('ProfileEdit') }
    setOpenPhotoRateNotifyDialog(false)
  }

  // ChatMemoTutorialDialog
  const showChatMemoTutorialDialog = async () => {
    if (!user) { return }
    if (new Date(Features.ChatMemo.releaseDate) < new Date(user.created_at)) { return }
    const dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'chat_memo_tutorial_dialog')
    setOpenChatMemoTutorialDialog(!dialogDisyplayed)
  }

  const closeChatMemoTutorialDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'chat_memo_tutorial_dialog')
    setOpenChatMemoTutorialDialog(false)
  }

  // NewUpdateDialog
  const checkIsNewUpdate = async () => {
    if (!user) { return }
    let dialogDisyplayed
    // Matching202201
    if (new Date(Features.Matching202201.releaseDate) < new Date(user.created_at)) { return }
    dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'Update.Matching202201')
    setOpenUpdateNaviDialog(!dialogDisyplayed)
    // PhotoTutorial202202
    if (new Date(Features.PhotoTutorial202202.releaseDate) < new Date(user.created_at)) { return }
    dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'Update.PhotoTutorial202202')
    setOpenPhotoTutorialUpdateDialog(!dialogDisyplayed)
  }

  const closeNewUpdateDialog = (target) => {
    if (target === 'Matching202201') {
      BaseComponent.createReadStateDialogDisplayed(props, 'Update.Matching202201')
      setOpenUpdateNaviDialog(false)
    }
    if (target === 'PhotoTutorial202202') {
      BaseComponent.createReadStateDialogDisplayed(props, 'Update.PhotoTutorial202202')
      setOpenPhotoTutorialUpdateDialog(false)
    }
  }

  const checkIsNewCampaign = async () => {
    // JR西タイアップイベント
    try {
      const data = await Api.getCampaignMaster({ campaign_type: 'jr_west_poc' })
      if (!data) { return }

      const campaignStartDate = new Date(data.campaign_start_at)
      const campaignEndDate = new Date(data.campaign_end_at)
      const today = new Date()
      if (campaignStartDate <= today && today < campaignEndDate) {
        const dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'Campaign.JRWestPoC')
        setOpenFoodReportEventDialog(!dialogDisyplayed)
      }
    } catch (error) {
      // ignore
      console.log(error)
    }
  }

  const closeNewCampaignDialog = (target) => {
    if (target === 'JRWestPoC') {
      BaseComponent.createReadStateDialogDisplayed(props, 'Campaign.JRWestPoC')
      setOpenFoodReportEventDialog(false)
    }
  }

  const checkPassed24hours = (holdAt) => {
    if (!holdAt) { return true }
    const hourDiff = calculateHourDiff(new Date(holdAt), new Date())
    return hourDiff > 24
  }

  const getMyDialogs = async () => {
    try {
      const res = await Api.getMyDialogs()
      setMyDialogs(res)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // 1つずつポップアップを表示
  useEffect(() => {
    openMyDialog()
  }, [myDialogs])

  const openMyDialog = async () => {
    if (myDialogs.length === 0) { return }
    // モニター依頼
    const askMonitorUser = myDialogs.find(d => d.type === 'Ask.MonitorUser')
    if (askMonitorUser) {
      setOpenMonitorUserDialog(true)
      setMonitorUserParams(askMonitorUser)
      return
    }
    // モニターユーザーに対するヒアリング依頼
    const askMonitorUserHearing = myDialogs.find(d => d.type === 'Ask.MonitorUserHearing')
    if (askMonitorUserHearing) {
      setOpenMonitorUserHearingDialog(true)
      setMonitorUserHearingParams(askMonitorUserHearing)
      return
    }
    // 有料化ユーザーに対するヒアリング依頼
    const askPaidUserHearing = myDialogs.find(d => d.type === 'Ask.PaidUserHearing')
    if (askPaidUserHearing) {
      setOpenPaidUserHearingDialog(true)
      setPaidUserHearingParams(askPaidUserHearing)
      return
    }
    // カップルヒアリング依頼
    const askCoupleHearing = myDialogs.find(d => d.type === 'Ask.CoupleHearing')
    if (askCoupleHearing) {
      setOpenCoupleHearingDialog(true)
      setCoupleHearingParams(askCoupleHearing)
      return
    }
    // プラン割引延長のお知らせ
    const infoPlanDiscountExtended = myDialogs.find(d => d.type.startsWith('Info.PlanDiscountExtended.'))
    if (infoPlanDiscountExtended) {
      setOpenDiscountTermExtentionDialog(true)
      setDiscountTermExtentionData(infoPlanDiscountExtended)
      return
    }
    // 無料期間延長
    const infoFreeTermExtended = myDialogs.find(d => d.type.startsWith('Info.FreeTermExtended.'))
    if (infoFreeTermExtended) {
      setOpenFreeTermExtentionDialog(true)
      setFreeTermExtentionData(infoFreeTermExtended)
      return
    }
    // フリープラン案内
    const infoFreePlan = myDialogs.find(d => d.type.startsWith('Info.FreePlan.'))
    if (infoFreePlan) {
      setFreePlanData(infoFreePlan)
      setOpenFreePlanDialog(true)
      return
    }
    // 映画予告イベントのお知らせ（開始前）
    const infoMovieEventPre = myDialogs.find(d => d.type.startsWith('Info.MovieEventPre.'))
    if (infoMovieEventPre) {
      setOpenMovieEventPreDialog(checkPassed24hours(Storage.InfoMovieEventPre.value))
      setMovieEventPreParams(infoMovieEventPre?.movie_event)
      return
    }
    // 映画予告イベントのお知らせ（開始後）
    const infoMovieEvent = myDialogs.find(d => d.type.startsWith('Info.MovieEvent.'))
    if (infoMovieEvent) {
      setOpenMovieEventDialog(checkPassed24hours(Storage.InfoMovieEvent.value))
      setMovieEventParams(infoMovieEvent?.movie_event)
      return
    }
    // 無料期間延長チャレンジのお知らせ
    const progressMissionFreeTermExtension = myDialogs.find(d => d.type.startsWith('Progress.Mission.FreeTermExtension.'))
    if (progressMissionFreeTermExtension) {
      handleProgressMissionFreeTermExtension(progressMissionFreeTermExtension)
      return
    }
    // ウィンターイベントのお知らせ
    const infoWinterEvent = myDialogs.find(d => d.type === 'Info.WinterEvent2022')
    if (infoWinterEvent) {
      setOpenWinterEventAnnounceDialog(true)
      setWinterEventMissions(infoWinterEvent?.missions)
      return
    }
    // バレンタインイベントのお知らせ
    const infoValentineEvent = myDialogs.find(d => d.type === 'Info.ValentineEvent2023')
    setOpenValentineEventAnnounceDialog(!!infoValentineEvent)
    setValentineEventMissions(infoValentineEvent?.missions)
    // フロントエンドで表示判定しているポップアップリスト
    // TODO バックエンドで表示判定を行う
    const checkingFromFrontendPopupList = [
      openRemindRequestDialog,
      openNewProfileItemDialog,
      openAutoPhotoAdjustmentDialog,
      openRatingUpDialog,
      open,
      openApproveLoverDialog,
      openLoverEntryDialog,
      openLoverRejectDialog,
      openOrganizationsDialog,
      openNewCompaniesDialog,
      openMatchingTutorialUpdatedDialog,
      openSelfIntroductionTemplateDialog,
      openPhotoRateNotifyDialog,
      openChatMemoTutorialDialog,
      openUpdateNaviDialog,
      openPhotoTutorialUpdateDialog,
      openFoodReportEventDialog,
    ]
    // アプリ評価
    const askAppReview = myDialogs.find(d => d.type === 'Ask.AppReview')
    if (askAppReview && !checkingFromFrontendPopupList.includes(true)) {
      if (openAppReviewDialog()) {
        BaseComponent.createReadStateDialogDisplayed(props, 'Ask.AppReview')
        BaseComponent.gaModalView('AppReview')
      }
      return
    }

    const smsAuthSetting = myDialogs.find(d => d.type === 'Info.NeedPhoneNumber')
    if (smsAuthSetting) {
      setOpenSmsAuthSettingRequestDialog(true)
      setSmsAuthSettingRequestCanSkip(smsAuthSetting?.can_skip)
      setSmsAuthSettingRequestLimit(smsAuthSetting?.limit)
      return
    }

    // 写真へのアドバイス
    const advicePhoto = myDialogs.find(d => d.type === 'Advice.Photo')
    if (advicePhoto) {
      setOpenAdvicePhotoDialog(true)
      props.setAdvicePhoto(advicePhoto.photo_features)
    }
  }

  const openAppReviewDialog = () => {
    // iOS
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.openAppReviewDialog) {
      window.webkit.messageHandlers.openAppReviewDialog.postMessage(null)
      return true
    }
    // Android
    if (window.android && window.android.openAppReviewDialog) {
      window.android.openAppReviewDialog()
      return true
    }
    return false
  }

  const handleProgressMissionFreeTermExtension = dialog => {
    if (!dialog) { return }
    if (!dialog.type) { return }
    if (dialog.type.endsWith('.0')) {
      setOpenFreeTermExtensionChallengeNotifyDialog(true)
    } else {
      setFriendApplyMission(dialog.missions.find(m => m.mission_type === 'friend_apply'))
      setChatOpenMission(dialog.missions.find(m => m.mission_type === 'chat_open'))
      setOpenFreeTermExtensionChallengeDialog(true)
    }
  }

  const closeMonitorUserDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'Ask.MonitorUser')
    setOpenMonitorUserDialog(false)
    setMyDialogs(myDialogs.filter(d => d.type !== 'Ask.MonitorUser'))
  }

  const closeMonitorUserHearingDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'Ask.MonitorUserHearing')
    setOpenMonitorUserHearingDialog(false)
    setMyDialogs(myDialogs.filter(d => d.type !== 'Ask.MonitorUserHearing'))
  }

  const closePaidUserHearingDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'Ask.PaidUserHearing')
    setOpenPaidUserHearingDialog(false)
    setMyDialogs(myDialogs.filter(d => d.type !== 'Ask.PaidUserHearing'))
  }

  const closeCoupleHearingDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'Ask.CoupleHearing')
    setOpenCoupleHearingDialog(false)
    setMyDialogs(myDialogs.filter(d => d.type !== 'Ask.CoupleHearing'))
  }

  const closeDiscountTermExtentionDialog = (dialogInfo) => {
    BaseComponent.createReadStateDialogDisplayed(props, dialogInfo.type)
    setOpenDiscountTermExtentionDialog(false)
    setMyDialogs(myDialogs.filter(d => d.type !== dialogInfo.type))
  }

  const closeFreeTermExtentionDialog = (dialogInfo) => {
    BaseComponent.createReadStateDialogDisplayed(props, dialogInfo.type)
    setOpenFreeTermExtentionDialog(false)
    setMyDialogs(myDialogs.filter(d => d.type !== dialogInfo.type))
  }

  const closeFreePlanDialog = (dialogType) => {
    BaseComponent.createReadStateDialogDisplayed(props, dialogType)
    setOpenFreePlanDialog(false)
    setMyDialogs(myDialogs.filter(d => d.type !== dialogType))
  }

  const holdMovieEventPreDialog = () => {
    Storage.InfoMovieEventPre.value = new Date()
    setOpenMovieEventPreDialog(false)
    setMyDialogs(myDialogs.filter(d => !d.type.startsWith('Info.MovieEventPre.')))
  }

  const closeMovieEventPreDialog = (dialogType) => {
    BaseComponent.createReadStateDialogDisplayed(props, `Info.MovieEventPre.${dialogType}`)
    Storage.InfoMovieEventPre.clear()
    setOpenMovieEventPreDialog(false)
    setMyDialogs(myDialogs.filter(d => !d.type.startsWith('Info.MovieEventPre.')))
  }

  const holdMovieEventDialog = () => {
    Storage.InfoMovieEvent.value = new Date()
    setOpenMovieEventDialog(false)
    setMyDialogs(myDialogs.filter(d => !d.type.startsWith('Info.MovieEvent.')))
  }

  const closeMovieEventDialog = (dialogType) => {
    BaseComponent.createReadStateDialogDisplayed(props, `Info.MovieEvent.${dialogType}`)
    Storage.InfoMovieEvent.clear()
    setOpenMovieEventDialog(false)
    setMyDialogs(myDialogs.filter(d => !d.type.startsWith('Info.MovieEvent.')))
  }

  const closeFreeTermExtensionChallengeDialog = (totalProgress) => {
    BaseComponent.createReadStateDialogDisplayed(props, `Progress.Mission.FreeTermExtension.${totalProgress}`)
    setOpenFreeTermExtensionChallengeDialog(false)
    setMyDialogs(myDialogs.filter(d => !d.type.startsWith('Progress.Mission.FreeTermExtension.')))
  }

  const closeFreeTermExtensionChallengeNotifyDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'Progress.Mission.FreeTermExtension.0')
    setOpenFreeTermExtensionChallengeNotifyDialog(false)
    setMyDialogs(myDialogs.filter(d => !d.type.startsWith('Progress.Mission.FreeTermExtension.')))
  }

  const closeWinterEventAnnounceDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'Info.WinterEvent2022')
    setOpenWinterEventAnnounceDialog(false)
    setMyDialogs(myDialogs.filter(d => d.type !== 'Info.WinterEvent2022'))
  }

  const closeValentineEventAnnounceDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'Info.ValentineEvent2023')
    setOpenValentineEventAnnounceDialog(false)
  }

  const closeConfirmDialog = async () => {
    try {
      await BaseComponent.updateAlreadyRead(props, notificationForConfirmDialog)
      setOpenConfirmDialog(false)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      BaseComponent.loadNotifications(props)
    }
  }

  if (!user) { return null }
  if (!tutorialReadStates?.includes('app')) { return null }
  if (!tutorialReadStates?.includes('matching')) { return null }

  return (
    <React.Fragment>
      {applyLoverFriends.map((friend, index) => (
        <NotifyLoveReceiveDialog
          key={index}
          open={friend.open}
          friend={friend}
          handleNext={() => handleApproveLoverNext(index)}
          handleHold={() => handleApproveLoverHold(index)}
        />
      ))}
      <RemindRequestDialog
        open={openRemindRequestDialog}
        handleClose={handleCloseRemindRequestDialog}
      />
      <NewProfileItemDialog
        open={openNewProfileItemDialog}
        item={newProfileLabels}
        handleInput={handleInputNewProfileItemDialog}
        handleClose={handleCloseNewProfileItemDialog}
      />
      <NewProfileItemDialog
        open={openAutoPhotoAdjustmentDialog}
        item="写真を自動調整する"
        handleInput={handleInputAutoPhotoAdjustmentDialog}
        handleClose={handleCloseAutoPhotoAdjustmentDialog}
      />
      <NewProfileItemDialog
        open={openFixProfileItemDialog}
        id={newProfileItemDialogId}
        text={fixProfileText}
        handleClose={handleCloseFixProfileItemDialog}
      />
      <ImportantConfirmDialog
        open={open}
        title="お知らせ"
        confirm="yes"
        onClose={handleClose}
        apply_friend_count={apply_friend_count}
        apply_lover_count={apply_lover_count}
        approve_friend_cont={approve_friend_count}
      />
      <RatingUpDialog
        {...props}
        open={openRatingUpDialog}
        notifications={ratingUpNotifications}
        title="お知らせ"
        onClose={closeRatingUpDialog}
        user={user}
      />
      <DialogThemeProvider color="default">
        <Dialog open={open && !isEmpty(message)} onClose={handleClose}>
          <DialogTitle disableTypography>お知らせ</DialogTitle>
          <DialogContent>
            <Typography variant="body1">{message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>確認</Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
      <ImportantConfirmDialog
        open={openApproveLoverDialog}
        title={'お付き合いリクエスト\n承認時注意事項'}
        message={'お付き合いが成立した場合、他のトーク中の異性は必然的に友達解消となります。\n10日以内に返事がない、もしくはお断りした場合は友達解消となります。'}
        loveconfirm="yes"
        handleApprove={() => setOpenLoverEntryDialog(true)}
        handleReject={() => setOpenLoverRejectDialog(true)}
        handleHold={handleApproveLoverDialogHold}
      />
      <LoverEntryDialog
        {...props}
        open={openLoverEntryDialog}
        onSent={handleSentLoverEntry}
        onCancel={() => setOpenLoverEntryDialog(false)}
        friend={stateFriend}
        lover_type="approve"
      />
      <LoverEntryDialog
        {...props}
        open={openLoverRejectDialog}
        onSent={handleSentLoverReject}
        onCancel={() => setOpenLoverRejectDialog(false)}
        friend={stateFriend}
        lover_type="reject"
      />
      { /* 廃止予定 */ }
      <NewOrganizationsDialog
        open={openOrganizationsDialog}
        onOk={handleMoveOrganizations}
        onClose={handleCloseNewOrganizationsDialog}
        organizations={organizations}
      />
      <NewOrganizationsDialog
        open={openNewCompaniesDialog}
        organizations={newCompanyNames}
        onOk={goToOrganizations}
        onClose={onCloseNewCompaniesDialog}
      />
      <MatchingTutorialUpdatedDialog
        open={openMatchingTutorialUpdatedDialog}
        onClose={closeMatchingTutorialUpdatedDialog}
        user={user}
        {...other}
      />
      <NewFeatureDialog
        open={openNewFeatureDialog}
        id={newFeatureDialogId}
        onConfirm={handleNewFeatureConfirm}
        onClose={handleNewFeatureClose}
      />
      <SelfIntroductionNotifyDialog
        open={openSelfIntroductionTemplateDialog}
        user={user}
        onConfirm={() => handleSelfIntroductionConfirm(true)}
        onClose={() => handleSelfIntroductionConfirm(false)}
      />
      <PhotoRateNotifyDialog
        open={openPhotoRateNotifyDialog}
        user={user}
        photoRate={user.photo_rating}
        onConfirm={() => handlePhotoRateConfirm(true)}
        onClose={() => handlePhotoRateConfirm(false)}
      />
      <ChatMemoTutorialDialog
        {...props}
        user={user}
        open={openChatMemoTutorialDialog}
        onClose={closeChatMemoTutorialDialog}
        showType='dialog'
      />
      <NewUpdateDialog
        open={openUpdateNaviDialog}
        title={'紹介ナビが\n使いやすくアップデート！'}
        subtitle={'変更点'}
        imageSrc={NaviUpdateImg}
        imgSrcSize={182}
        onClose={() => closeNewUpdateDialog('Matching202201')}
      >
        <span className={classes.naviText}>
          ・未ログインでも毎日紹介 ＊1
        </span><br />
        <span className={classes.naviSubText}>
          週一回のログインでも7人の紹介になります（前回ログイン日から当日までの間で、最大7日分となります）。
        </span><br />
        <span className={classes.naviText}>
          ・紹介表示期間が３日→7日に延長 ＊2
        </span><br />
        <span className={classes.naviSubText}>
          お相手からのリクエストも同様に7日表示になります。
        </span><br />
        <br />
        <span className={classes.naviSubText}>
          ＊1: スタンダードプランご利用時のみ<br />
          ＊2: 2022年1月26日12時以降の紹介から適用
        </span>
      </NewUpdateDialog>
      <NewUpdateDialog
        open={openPhotoTutorialUpdateDialog}
        title={'チュートリアルに\n新項目が追加されたよ！'}
        subtitle={'追加内容'}
        imageSrc={PhotoTutorialUpdateImg}
        imgSrcSize={182}
        onClose={() => closeNewUpdateDialog('PhotoTutorial202202')}
      >
        <span className={classes.naviText}>
          ・手軽に「異性に好印象な写真」を撮る方法
        </span><br />
        <span className={classes.naviSubText}>
          パソコンを使い、1人で手軽にプロフィール写真を撮影する方法をご紹介しています。
        </span>
      </NewUpdateDialog>
      <NewCampaignDialog
        open={openFoodReportEventDialog}
        title={'JR西日本グループとの\n食レポイベントが始まるよ！'}
        subtitle={'イベント概要'}
        text={'デートで対象店舗に\n来店＆食レポ投稿すると、\nそれぞれに次回使える\nギフト券をプレゼント！'}
        notice={'＊関西圏の一部地域限定のイベントとなります。\n＊詳細な適用条件はイベント対象者に送信される内容をご確認ください。'}
        imageSrc={foodReportPopUpImg}
        onClose={() => closeNewCampaignDialog('JRWestPoC')}
      >
      </NewCampaignDialog>
      <MonitorUserDialog
        open={openMonitorUserDialog}
        params={monitorUserParams}
        onClose={closeMonitorUserDialog}
      />
      <MonitorUserDialog
        open={openMonitorUserHearingDialog}
        params={monitorUserHearingParams}
        onClose={closeMonitorUserHearingDialog}
      />
      <CooperateDialog
        open={openPaidUserHearingDialog}
        params={paidUserHearingParams}
        onClose={closePaidUserHearingDialog}
        user={user}
      />
      <CooperateDialog
        open={openCoupleHearingDialog}
        params={coupleHearingParams}
        onClose={closeCoupleHearingDialog}
        user={user}
      />
      <DiscountTermExtentionDialog
        open={openDiscountTermExtentionDialog}
        onClose={closeDiscountTermExtentionDialog}
        data={discountTermExtentionData}
      />
      <FreeTermExtentionDialog
        open={openFreeTermExtentionDialog}
        onClose={closeFreeTermExtentionDialog}
        data={freeTermExtentionData}
      />
      <FreePlanSuggestDialog
        open={openFreePlanDialog}
        onClose={closeFreePlanDialog}
        subscription={subscription}
        dialogType={freePlanData && freePlanData.type}
      />
      <MovieEventAnnounceDialog
        open={openMovieEventPreDialog}
        params={movieEventPreParams}
        onConfirm={() => props.setScreen('Theater')}
        handleHold={holdMovieEventPreDialog}
        onClose={closeMovieEventPreDialog}
      />
      <MovieEventAnnounceDialog
        open={openMovieEventDialog}
        params={movieEventParams}
        onConfirm={() => props.setScreen('Theater')}
        handleHold={holdMovieEventDialog}
        onClose={closeMovieEventDialog}
      />
      <FreeTermExtensionChallengeDialog
        open={openFreeTermExtensionChallengeDialog}
        onClose={closeFreeTermExtensionChallengeDialog}
        friendApplyMission={friendApplyMission}
        chatOpenMission={chatOpenMission}
        {...props}
      />
      <FreeTermExtensionChallengeNotifyDialog
        open={openFreeTermExtensionChallengeNotifyDialog}
        onClose={closeFreeTermExtensionChallengeNotifyDialog}
      />
      <WinterEventAnnounceDialog
        open={openWinterEventAnnounceDialog}
        onConfirm={() => {
          closeWinterEventAnnounceDialog()
          setOpenWinterEventChallengeDialog(true)
        }}
        onClose={closeWinterEventAnnounceDialog}
        missions={winterEventMissions}
      />
      <WinterEventChallengeDialog
        open={openWinterEventChallengeDialog}
        onClose={() => setOpenWinterEventChallengeDialog(false)}
        missions={winterEventMissions}
      />
      <ValentineEventAnnounceDialog
        open={openValentineEventAnnounceDialog}
        onConfirm={() => {
          closeValentineEventAnnounceDialog()
          setOpenValentineEventChallengeDialog(true)
        }}
        onClose={closeValentineEventAnnounceDialog}
        missions={valentineEventMissions}
      />
      <ValentineEventChallengeDialog
        open={openValentineEventChallengeDialog}
        onClose={() => setOpenValentineEventChallengeDialog(false)}
        missions={valentineEventMissions}
      />
      <XmasCampaignAnnounceDialog
        open={openXmasCampaignAnnounceDialog}
        onClose={async () => {
          await BaseComponent.updateAlreadyRead(props, notificationForXmasCampaignAnnounceDialog)
          setOpenXmasCampaignAnnounceDialog(false)
        }}
        type={campaignType}
        code={campaignCode}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={closeConfirmDialog}
        confirm="yes"
        title={notificationForConfirmDialog?.title || ''}
        message={notificationForConfirmDialog?.body || ''}
        messageLeft
        imageUrl={notificationForConfirmDialog?.image_url}
      />
      <FBAnnounceDialog
        {...other}
        open={openFBAnnounceDialog}
        onConfirm={() => props.setScreen('Facebook')}
        onClose={() => setOpenFBAnnounceDialog(false)}
        notification={notificationForFBAnnounceDialog}
      />
      <FBAnnounceDialog
        {...other}
        open={openFBNewAnnounceDialog}
        onConfirm={() => props.setScreen('Facebook')}
        onClose={() => setOpenFBNewAnnounceDialog(false)}
        notification={notificationForFBNewAnnounceDialog}
      />
      <SpecialOfferDialog
        {...other}
        open={openSpecialOfferDialog}
        offerId={specialOfferNotification?.special_offer_id}
        onClick={() => props.setScreen('SpecialOffer')}
        onClose={async () => {
          await BaseComponent.updateAlreadyRead(props, [specialOfferNotification])
          setOpenSpecialOfferDialog(false)
        }}
      />
      <FocusPointAdviceDialog
        {...other}
        open={openFocusPointAdviceDialog}
        onClose={() => {
          setOpenFocusPointAdviceDialog(false)
          BaseComponent.createReadStateDialogDisplayed(props, `Info.FocusPointAdvice.${focusPointAdviceNum}`)
        }}
        user={user}
        master={master}
      />
      <SmsAuthSettingRequestDialog
        {...other}
        open={openSmsAuthSettingRequestDialog}
        canSkip={smsAuthSettingRequestCanSkip}
        limit={smsAuthSettingRequestLimit}
        user={user}
        onClick={async () => {
          await BaseComponent.createReadStateDialogDisplayed(props, 'Info.NeedPhoneNumber')
          props.setScreen('MyPhoneNumber')
        }}
        onClose={async () => {
          await BaseComponent.createReadStateDialogDisplayed(props, 'Info.NeedPhoneNumber')
          setOpenSmsAuthSettingRequestDialog(false)
        }}
      />
      <AdvicePhotoDialog
        {...other}
        user={user}
        open={openAdvicePhotoDialog}
        onClose={async () => {
          await BaseComponent.createReadStateDialogDisplayed(props, 'Advice.Photo')
          setOpenAdvicePhotoDialog(false)
        }}
      />
      <DocomoRugbyTicketPresentDialog
        open={openDocomoRugbyTicketPresentDialog}
        onClose={async () => {
          await BaseComponent.updateAlreadyRead(props, [docomoRugbyTicketPresentNotification])
          setOpenDocomoRugbyTicketPresentDialog(false)
        }}
      />
      <ApprovePushDialog
        {...props}
        open={openApprovePushDialog}
        onClose={async () => {
          await BaseComponent.createReadStateDialogDisplayed(props, 'Info.ApprovePush')
          setOpenApprovePushDialog(false)
        }}
        user={user}
      />
    </React.Fragment>
  )
}

NotifyDialog.propTypes = {
  user: PropTypes.object,
  friends: PropTypes.array,
  entries: PropTypes.object,
  master: PropTypes.object,
  subscription: PropTypes.object,
  notifications: PropTypes.array,
  userId: PropTypes.string,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
  setFriend: PropTypes.func,
  screen: PropTypes.string,
  requestSuccessMessage: PropTypes.string,
  tutorialReadStates: PropTypes.array,
  setAdvicePhoto: PropTypes.func,
}
