import React from 'react'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import {
  isSecretQuestion, isFoodReport,
  isDatingAfterFollowGuidance, isDatingRecommendedQuestions,
  isTalkAssistGuidance, isRugbyTicketPresent20250208,
} from 'commons/utility'
import FoodReportGuidance from 'components/foodreport/FoodReportGuidance'
import ShopLinks from 'components/foodreport/ShopLinks'
import AfterDatingGuidance from 'components/parts/AfterDatingGuidance'
import BaseComponent from 'components/parts/BaseComponent'
import CommonSpeech from 'components/parts/CommonSpeech'
import DatingImpressionTutorialDialog from 'components/parts/DatingImpressionTutorialDialog'
import DatingRecommendedQuestions from 'components/parts/DatingRecommendedQuestions'
import DemoDialog from 'components/parts/DemoDialog'
import DocomoRugbyTicketPresentGuidance from 'components/parts/DocomoRugbyTicketPresentGuidance'
import NaviButton from 'components/parts/NaviButton'
import NaviQuestion from 'components/parts/NaviQuestion'
import TalkAssistGuidance from 'components/parts/TalkAssistGuidance'
import PhotoIssueDialog from 'components/photo-tutorial/PhotoIssueDialog'
import PhotoTutorialDialog from 'components/photo-tutorial/PhotoTutorialDialog'
import AImage from 'images/img_character_a.png'
import AIcon from 'images/img_character_a_talk.png'
import UnImage from 'images/img_character_un.png'
import UnIcon from 'images/img_character_un_talk.png'
import BackgroundImage from 'images/img_secret_message.png'

const photoTemplateIds = ['advice-02-1-2', 'advice-02-2-2']
const datingImpressionTemplateIds = ['advice-07-1-1', 'advice-07-2-1', 'advice-07-3-1']
const demoTemplateId = 'advice-01-1-5'
const memoTemplateId = 'follow_after_dating-06'
const photoIssueTemplateId = 'advice-10-1-1'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    display: 'flex',
    '-webkit-touch-callout': 'none',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  faceAvatar: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 36,
    height: 36,
    objectFit: 'contain',
  },
  angelSpeach: theme.styles.naviSpeach(Const.colors.angelBackground),
  devilSpeach: theme.styles.naviSpeach(Const.colors.devilBackground),
  angelSecretSpeach: theme.styles.naviSpeachSecret(Const.colors.angelSecretBorder),
  devilSecretSpeach: theme.styles.naviSpeachSecret(Const.colors.devilSecretBorder),
  angelDemoSpeach: {
    backgroundColor: theme.palette.background.default,
    border: `2px solid ${Const.colors.angelSecretBorder}`,
    borderRadius: '25px 25px 25px 5px',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: 'calc(100% - 92px)',
  },
  devilDemoSpeach: {
    backgroundColor: theme.palette.background.default,
    border: `2px solid ${Const.colors.devilSecretBorder}`,
    borderRadius: '25px 25px 25px 5px',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: 'calc(100% - 92px)',
  },
  memoSpeach: {
    backgroundColor: '#589BFB34',
    border: `2px dotted ${Const.colors.devilSecretBorder}`,
    borderRadius: '25px 25px 25px 25px',
    boxSizing: 'border-box',
    width: 'calc(100% - 92px)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
  },
  memoTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    fontSize: 14,
    fontWeight: 'bold',
    color: Const.colors.devilSecret,
    textAlign: 'center',
  },
  memoText: {
    '-webkit-user-select': 'none',
    '& p': {
      fontWeight: 'bold',
    }
  },
  memoOpen: {
    fontSize: 14,
    fontWeight: 'bold',
    width: 172,
    height: 40,
    backgroundColor: Const.colors.devilSecret,
    color: theme.palette.secondary.contrastText,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  speachText: {
    '-webkit-user-select': 'none',
  },
  naviTo: {
    color: '#555555',
    fontSize: 10,
    lineHeight: 1,
    whiteSpace: 'pre',
    marginLeft: theme.spacing(7),
    '-webkit-user-select': 'none',
  },
  spaceContainer: {
    marginRight: theme.spacing(2),
    '-webkit-user-select': 'none',
  },
  message: {
    fontSize: 14,
  },
  secretContainer: {
    width: 338,
    minHeight: 176,
    ...theme.styles.secretRoomColor,
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(3),
    position: 'relative',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
  },
  secretTitle: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  secretSubTitle: {
    marginTop: theme.spacing(2),
    fontSize: 20,
    fontWeight: 'bold',
    color: '#555555',
    textAlign: 'center',
    maxWidth: 240,
  },
  secretOpen: {
    fontSize: 14,
    fontWeight: 'bold',
    width: 230,
    height: 40,
    backgroundColor: '#616AFA',
    color: theme.palette.secondary.contrastText,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  imageA: {
    position: 'absolute',
    top: 40,
    right: 16,
    width: 30,
    transform: 'rotate(-15deg)',
  },
  imageUn: {
    position: 'absolute',
    top: 104,
    left: 16,
    width: 37,
    transform: 'rotate(15deg)',
  },
  badge: {
    top: 24,
    right: 8,
  },
  demo: {
    marginTop: theme.spacing(1),
  },
  messageDemo: {
    fontSize: 14,
    minHeight: 18,
  },
  closeButton: {
    width: 194,
    height: 40,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.secondary.contrastText,
    backgroundColor: Const.colors.devilSecret,
    marginTop: theme.spacing(2.5),
    '&:hover': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: Const.colors.devilSecret,
    },
  },
})

export class NaviSpeech extends CommonSpeech {
  constructor (props) {
    super(props)

    this.handleTap = this.handleTap.bind(this)
    this.handleAnswerQuestion = this.handleAnswerQuestion.bind(this)
    this.handleClickSecretButton = this.handleClickSecretButton.bind(this)
    this.handleClickPhotoTutorial = this.handleClickPhotoTutorial.bind(this)
    this.handleClosePhotoTutorial = this.handleClosePhotoTutorial.bind(this)
    this.handleClickDatingImpressionTutorial = this.handleClickDatingImpressionTutorial.bind(this)
    this.handleCloseDatingImpressionTutorial = this.handleCloseDatingImpressionTutorial.bind(this)
    this.handleClickDemo = this.handleClickDemo.bind(this)
    this.handleCloseDemo = this.handleCloseDemo.bind(this)
    this.handleClickMemo = this.handleClickMemo.bind(this)

    this.state = {
      openPhotoTutorialDialog: false,
      openDatingImpressionTutorialDialog: false,
      openDemoDialog: false,
      demoType: 1,
      demoSex: 'male',
      demoNickName: '',
      openPhotoIssueDialog: false,
    }
  }

  handleTap () {
    this.props.faceTap?.()
  }

  handleAnswerQuestion (value, inputMessage = null) {
    if (this.props.demo) {
      this.props.onAnswerDemoQuestion()
      return
    }
    if (this.props.onAnswerQuestion) {
      let msg = this.props.message
      this.props.onAnswerQuestion(msg, value, inputMessage)
    }
  }

  classNameOfMessageContainer () {
    const { message, secret, naviType, friend, classes } = this.props

    if (friend?.type === 'ai') { return naviType === 'devil' ? classes.devilDemoSpeach : classes.angelDemoSpeach }

    if (this.isCreateDemoLink(message.template_id)) {
      return classes.angelDemoSpeach
    }

    if (this.isMemoTemplate(message.template_id)) {
      return classes.memoSpeach
    }

    if (message.fav_question_type || secret) {
      return naviType === 'devil' ? classes.devilSecretSpeach : classes.angelSecretSpeach
    }

    return naviType === 'devil' ? classes.devilSpeach : classes.angelSpeach
  }

  handleClickConfirmButton = async (result) => {
    const { message } = this.props
    if (!message.confirm_result) {
      const success = await this.props.onConfirmSend(message, result)
      if (success) {
        message.confirm_result = result
      }
    }
  }

  getTextColor () {
    if (this.props.secret) {
      if (this.props.naviType === 'devil') {
        return Const.colors.devilSecret
      } else {
        return Const.colors.angelSecret
      }
    } else {
      if (this.props.naviType === 'devil') {
        return Const.colors.devil
      } else {
        return Const.colors.angel
      }
    }
  }

  handleClickSecretButton () {
    this.props.onSecretRoom(this.props.message)
  }

  handleClickPhotoTutorial () {
    this.setState({ openPhotoTutorialDialog: true })
  }

  handleClosePhotoTutorial () {
    this.setState({ openPhotoTutorialDialog: false })
  }

  handleClickDatingImpressionTutorial () {
    this.setState({ openDatingImpressionTutorialDialog: true })
  }

  handleCloseDatingImpressionTutorial () {
    this.setState({ openDatingImpressionTutorialDialog: false })
  }

  handleClickDemo (type) {
    this.setState({
      demoType: type,
      demoSex: this.props.user.sex,
      demoNickName: this.props.user.nick_name,
      openDemoDialog: true,
    })
  }

  handleCloseDemo () {
    this.setState({ openDemoDialog: false })
  }

  handleClickMemo () {
    this.props.onMemoOpen()
  }

  createNaviSpeach () {
    const { classes, ...other } = this.props
    const { message, friend, selectedChatId } = this.props
    const textColor = this.getTextColor()

    return (
      <>
        <div id={message.template_id || ''} className={classes.root}>
          <div className={classes.avatarContainer}>
            <img
              src={this.props.naviType === 'devil' ? UnIcon : AIcon}
              className={classes.faceAvatar}
              onClick={this.handleTap}
              alt=""
            />
          </div>
          <div
            className={this.classNameOfMessageContainer()}
            onContextMenu={this.handleContextMenu}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
            style={{ filter: message.id === selectedChatId ? 'contrast(0.5)' : 'none' }}
          >
            <div
              id={`message-text-${message.id}`}
              className={classes.speachText}
            >
              {this.createChatMessageElements(textColor, classes.message)}
              {this.createPhotoTutorialLink()}
              {this.createDatingImpressionTutorialLink(textColor, classes.message)}
              {this.createDemoLink()}
              {this.createShopLink(textColor)}
              {this.createPhotoIssueDialog()}
            </div>
            {message.fav_question_type ? (
              <NaviQuestion
                {...other}
                message={message}
                friend={friend}
                master={this.props.master}
                onAnswerQuestion={this.handleAnswerQuestion}
                naviType={this.props.naviType}
              />
            ) : null}
            {message.confirm_type && (
              <Grid container>
                <NaviButton
                  naviType={this.props.naviType}
                  selected={message.confirm_result === 'yes'}
                  onClick={() => this.handleClickConfirmButton('yes')}
                  disabled={friend?.type === 'closed'}
                >
                  送信する
                </NaviButton>
                <NaviButton
                  naviType={this.props.naviType}
                  selected={message.confirm_result === 'no'}
                  onClick={() => this.handleClickConfirmButton('no')}
                  disabled={friend?.type === 'closed'}
                >
                  送信しない
                </NaviButton>
              </Grid>
            )}
            {message.quote_template &&
              (!message.template_user_1 || message.template_user_1 !== this.props.userId) && (
              <NaviButton
                naviType={this.props.naviType}
                onClick={this.props.onClickUseTemplate}
                selected={false}
                disabled={false}
                fullWidth
              >
                { /^follow-\d+$/.test(message.template_id) ? '応援する' : '聞いてみる' }
              </NaviButton>
            )}
          </div>
          <div className={classes.spaceContainer} />
        </div>
        {((friend && friend.type !== 'ai') || this.props.demo) && (
          <Typography className={classes.naviTo}>
            {message.navi_to ? '自分だけに表示' : '2人に表示'}
          </Typography>
        )}
      </>
    )
  }

  createMemoSpeach () {
    const { classes } = this.props
    const textColor = this.getTextColor()

    return (
      <div className={classes.root}>
        <div className={classes.avatarContainer}>
          <img
            src={this.props.naviType === 'devil' ? UnIcon : AIcon}
            className={classes.faceAvatar}
            onClick={this.handleTap}
            alt=""
          />
        </div>
        <div
          className={this.classNameOfMessageContainer()}
          onContextMenu={this.handleContextMenu}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
        >
          <Typography className={classes.memoTitle}>
            相手のことを忘れる前に
          </Typography>
          <div
            id={`message-text-${this.props.message.id}`}
            className={classes.memoText}
          >
            {this.createChatMessageElements(textColor, classes.message)}
          </div>
          <Button
            variant="contained"
            className={classes.memoOpen}
            onClick={this.handleClickMemo}
          >
            お相手メモ
          </Button>
        </div>
        <div className={classes.spaceContainer} />
      </div>
    )
  }

  createSecretQuestion () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Grid container justifyContent="center" alignItems="center">
          <div className={classes.secretContainer}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Typography className={classes.secretTitle}>
                秘密の質問コーナーだよ！
              </Typography>
              <Typography className={classes.secretSubTitle}>
                {this.props.message.secret_question_title}
              </Typography>
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={this.props.message.new_secret_message_count}
                invisible={this.props.message.new_secret_message_count === 0}
                overlap="rectangular"
              >
                <Button variant="contained" className={classes.secretOpen} onClick={this.handleClickSecretButton}>
                  二人の相性チェック！
                </Button>
              </Badge>
            </Grid>
            <img src={AImage} className={classes.imageA} alt=""/>
            <img src={UnImage} className={classes.imageUn} alt=""/>
          </div>
        </Grid>
      </div>
    )
  }

  isCreatePhotoTutoralLink (templateId) {
    if (!templateId) return false
    return photoTemplateIds.includes(templateId)
  }

  isCreateDatingImpressionTutoralLink (templateId) {
    if (!templateId) return false
    return datingImpressionTemplateIds.includes(templateId)
  }

  isCreateDemoLink (templateId) {
    if (!templateId) return false
    return demoTemplateId === templateId
  }

  isMemoTemplate (templateId) {
    if (!templateId) return false
    return memoTemplateId === templateId
  }

  createPhotoTutorialLink () {
    if (!this.isCreatePhotoTutoralLink(this.props.message.template_id)) { return null }

    const { classes, ...other } = this.props
    return (
      <div>
        <Button variant="text" className={classes.message} onClick={this.handleClickPhotoTutorial}>
          <u>写真チュートリアル</u>
        </Button>
        <PhotoTutorialDialog
          {...other}
          open={this.state.openPhotoTutorialDialog}
          onClose={this.handleClosePhotoTutorial}
          type='tutorial'
        />
      </div>
    )
  }

  createDatingImpressionTutorialLink (textColor, fontSize) {
    if (!this.isCreateDatingImpressionTutoralLink(this.props.message.template_id)) { return null }
    let count = Number(this.props.message.template_id.substr(10, 1))
    let style = { color: textColor }

    const { classes } = this.props
    return (
      <div>
        <Button variant="text" className={classes.message} onClick={this.handleClickDatingImpressionTutorial}>
          <u>{count === 1 ? 'ボクたちのアシストを確認' : '次のアシストを確認'}</u>
        </Button>
        <Typography style={style} className={fontSize ? fontSize : ''}>
          ※Tapするとポップアップが出るよ
        </Typography>
        <DatingImpressionTutorialDialog
          open={this.state.openDatingImpressionTutorialDialog}
          onClose={this.handleCloseDatingImpressionTutorial}
          sex={this.props.user.sex}
          count={count}
        />
      </div>
    )
  }

  createDemoLink () {
    if (!this.isCreateDemoLink(this.props.message.template_id)) { return null }

    const { classes, user, ...other } = this.props
    return (
      <div className={classes.demo}>
        <Button variant="text" className={classes.messageDemo} onClick={() => this.handleClickDemo(1)}>
          <u>①{user.sex === 'female' ? '相手の人となりがわかる編' : 'デートお誘いタイミング編'}</u>
        </Button>
        <Button variant="text" className={classes.messageDemo} onClick={() => this.handleClickDemo(2)}>
          <u>②{user.sex === 'female' ? '仕事内容を自然に聞く編' : '女性が好印象を抱くトーク編'}</u>
        </Button>
        <DemoDialog
          {...other}
          open={this.state.openDemoDialog}
          onClose={this.handleCloseDemo}
          type={this.state.demoType}
          user={this.props.user}
          sex={this.state.demoSex}
          nickName={this.state.demoNickName}
        />
      </div>
    )
  }

  isCreateShopLink (Ids) {
    if (!Ids) return false
    return Ids.length > 0
  }

  createShopLink (textColor) {
    // eslint-disable-next-line
    const { classes, ...others } = this.props
    if (!this.isCreateShopLink(this.props.message.shop_ids)) { return null }
    return (
      <ShopLinks
        shopIds={this.props.message.shop_ids}
        textColor={textColor}
        {...others}
      />
    )
  }

  isCreatePhotoIssueDialog (templateId) {
    if (!templateId) return false
    return photoIssueTemplateId === templateId
  }

  createPhotoIssueDialog () {
    if (!this.isCreatePhotoIssueDialog(this.props.message.template_id)) { return null }
    const { classes, user, ...other } = this.props
    return (
      <div>
        <Button variant="text" className={classes.message} onClick={() => this.setState({ openPhotoIssueDialog: true })}>
          <u>異性に見える自分のプロフィール</u>
        </Button>
        <PhotoIssueDialog
          {...other}
          open={this.state.openPhotoIssueDialog}
          onClose={() => this.setState({ openPhotoIssueDialog: false })}
          user={user}
          type="Home"
        />
      </div>
    )
  }

  createCloseConfirm () {
    const { classes, friend } = this.props
    const textColor = this.getTextColor()
    return (
      <div className={classes.root}>
        <div className={classes.avatarContainer}>
          <img
            src={this.props.naviType === 'devil' ? UnIcon : AIcon}
            className={classes.faceAvatar}
            onClick={this.handleTap}
            alt=""
          />
        </div>
        <div className={this.classNameOfMessageContainer()}>
          <div id={`message-text-${this.props.message.id}`} className={classes.speachText}>
            {this.createChatMessageElements(textColor, classes.message)}
          </div>
          <Button
            className={classes.closeButton}
            variant="contained"
            onClick={async () => {
              this.props.setLoading(true)
              try {
                await Api.deleteFriend(friend.id)
                await this.loadFriends()
                this.props.setScreen('Friends')
              } catch (error) {
                BaseComponent.handleApiError(this.props, error)
              } finally {
                this.props.setLoading(false)
              }
            }}
          >
            トークルームを終了する
          </Button>
        </div>
        <div className={classes.spaceContainer} />
      </div>
    )
  }

  render () {
    // eslint-disable-next-line
    const { classes, ...others } = this.props
    const { message, user } = this.props
    const msgType = message.message_type

    if (isTalkAssistGuidance(message)) {
      return <TalkAssistGuidance {...others} message={message} user={this.props.user} friend={this.props.friend} />
    }

    if (isRugbyTicketPresent20250208(message)) {
      return <DocomoRugbyTicketPresentGuidance />
    }

    if (isSecretQuestion(this.props.message)) {
      return this.createSecretQuestion()
    } else if (this.isMemoTemplate(message.template_id)) {
      return this.createMemoSpeach()
    } else if (isFoodReport(message) && msgType === 'food_report_form') {
      return (
        <FoodReportGuidance
          type="form"
          foodReportId={this.props.message.food_report_id}
          {...others}
        />
      )
    } else if (isFoodReport(message) && msgType === 'food_report_guidance') {
      return (
        <FoodReportGuidance
          type="guidance"
          {...others}
        />
      )
    } else if (isDatingAfterFollowGuidance(message)) {
      return <AfterDatingGuidance user={user} />
    } else if (isDatingRecommendedQuestions(message)) {
      return <DatingRecommendedQuestions friend={this.props.friend} message={message} handleTap={this.handleTap} />
    } else if (msgType === 'closed') {
      return this.createCloseConfirm()
    } else {
      return this.createNaviSpeach()
    }
  }
}

NaviSpeech.propTypes = {
  classes: PropTypes.object.isRequired,
  onConfirmSend: PropTypes.func.isRequired,
  onSecretRoom: PropTypes.func.isRequired,
  onMemoOpen: PropTypes.func.isRequired,
}

export default withStyles(styles)(NaviSpeech)
