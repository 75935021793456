import React, { useState, useEffect } from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import Api from 'commons/api'
import * as storage from 'commons/storage'
import { calculateHourDiff } from 'commons/utility'
import Chat from 'components/Chat'
import Tutorial from 'components/first-navigation/Tutorial.jsx'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import DemoNotificationDialog from 'components/parts/DemoNotificationDialog.jsx'
import FriendHome from 'components/parts/FriendHome.jsx'
import GraduatedDialog from 'components/parts/GraduatedDialog.jsx'
import HappinessSurveyDialog from 'components/parts/HappinessSurveyDialog.jsx'
import NotifyDialog from 'components/parts/NotifyDialog.jsx'
import UserHearingDialog from 'components/parts/UserHearingDialog.jsx'
import PhotoTutorialDialog from 'components/photo-tutorial/PhotoTutorialDialog'
import SurveyTopDialog from 'components/survey/SurveyTopDialog'

export default function Home (props) {
  const { user, requestSuccessMessage, friends, tutorialReadStates } = props
  const [isLover, setIsLover] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [openTutorial, setOpenTutorial] = useState(false)
  const [openGraduatedDialog, setOpenGraduatedDialog] = useState(false)
  const [friendState, setFriendState] = useState(null)
  const [openHappinessSurveyDialog, setOpenHappinessSurveyDialog] = useState(false)
  const [graduationConfirmationed, setGraduationConfirmationed] = useState(false)
  const [openPhotoTutorialDialog, setOpenPhotoTutorialDialog] = useState(false)
  const [openDemoNotificationDialog, setOpenDemoNotificationDialog] = useState(false)
  const [notAnsweredMasters, setNotAnsweredMasters] = useState([])

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    props.setScreen('Home')

    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  useEffect(() => {
    if (!user) { return }
    if (user.identification_status === 'checking') {
      props.setScreen('IdChecking')
    } else {
      graduationConfirmation()
      updateFcmToken()
      BaseComponent.loadMatchingState(props)
    }
    setFirstLoginDate()
  }, [user])

  useEffect(() => {
    (async () => {
      if (!tutorialReadStates) { return }
      if (!tutorialReadStates.length === 0) { return }
      if (!tutorialReadStates.includes('app')) {
        props.setHideAppNavigation(true)
        setOpenTutorial(true)
      } else if (!tutorialReadStates.includes('matching')) {
        // ignore
      } else if (requestSuccessMessage) {
        // ignore
      } else {
        // HappinessSurveyDialogを表示
        if (await showHappinessSurveyDialog()) {
          setOpenHappinessSurveyDialog(true)
        }
        // PhotoTutorialDialogを表示
        if (await showPhotoTutorialDialog()) {
          setOpenPhotoTutorialDialog(true)
        }
        // DemoNotificationDialogを表示
        if (await showDemoNotificationDialog()) {
          setOpenDemoNotificationDialog(true)
        }
        // アンケート取得後、未回答のアンケートを表示
        const masters = await getSurveyMasters()
        setNotAnsweredMasters(getNotAnsweredSurveyMasters(masters))
      }
    })()
  }, [tutorialReadStates])

  // ネイティブアプリからFCMトークンをlocalStorageに格納されている場合は更新
  const updateFcmToken = async () => {
    const token = storage.getFcmToken()
    if (!token) { return }

    try {
      const fcm_token = await Api.updateFcmToken(token)
      if (fcm_token.value === token) {
        storage.clearFcmToken()
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const graduationConfirmation = async () => {
    try {
      if (await BaseComponent.isGraduated(props)) {
        setIsLover(true)
        let friend = await BaseComponent.findLoverFriend(props)
        props.setFriend(friend)
        if (!friend.graduated_message_displayed) {
          setOpenGraduatedDialog(true)
          setFriendState(friend)
        }
      }
      setGraduationConfirmationed(true)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const setFirstLoginDate = async () => {
    if (user.first_login_date) { return }
    let date = new Date()
    if (storage.getFirstLoginDate()) {
      date = new Date(storage.getFirstLoginDate())
    }
    try {
      await BaseComponent.updateUser(props, { first_login_date: date })
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const showHappinessSurveyDialog = async () => {
    if (!tutorialReadStates.includes('matching')) { return false }
    if (requestSuccessMessage) { return false }
    if (!user.next_happiness_date) { return true }
    try {
      if (await BaseComponent.isGraduated(props)) {
        return false
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
    const today = new Date()
    const showDate = new Date(user.next_happiness_date + 'T00:00:00+09:00')
    return showDate < today
  }

  const showPhotoTutorialDialog = async () => {
    if (!tutorialReadStates.includes('matching')) { return false }
    try {
      if (await BaseComponent.isGraduated(props)) { return false }
      let passed = false
      const lastDate = storage.getLastDatePhotoTutorialDisplayed()
      if (isNullOrUndefined(lastDate)) {
        passed = true
      } else {
        const currentDate = new Date()
        const hourDiff = calculateHourDiff(new Date(lastDate), currentDate)
        // 1年経過
        if (hourDiff >= 8760) { passed = true }
      }
      if (passed) {
        const photo_rating = user.photo_rating
        if (isNullOrUndefined(photo_rating) || photo_rating !== 'good') {
          return true
        } else {
          const photos = await BaseComponent.loadMyGalleryItems()
          if (isNullOrUndefined(photos) || photos.length === 0) {
            return true
          }
        }
      } else {
        return false
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
    return false
  }

  const showDemoNotificationDialog = async () => {
    const endDate = new Date('2021-03-29T11:00:00+09:00')
    const checkedDate = new Date(user.identification_status_updated_at)
    if (checkedDate > endDate) return false
    const dailogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(props, 'demo_notification_dialog')
    return !dailogDisyplayed
  }

  const getSurveyMasters = async () => {
    try {
      const masters = await Api.getSurveyMasters(true)
      return masters
    } catch (error) {
      // noop
    }
  }

  const getNotAnsweredSurveyMasters = (surveyMasters) => {
    if (!surveyMasters) { return [] }
    const masters = surveyMasters.filter(master => {
      // JR東日本協業アンケートは除外
      if (master.survey_type === 'jr_east_1') { return false }
      // アンケート期間チェック
      const now = new Date()
      const startDate = new Date(master.start_at)
      const endDate = new Date(master.end_at)
      // 期間外のアンケートは除外（localStorageの値を削除）
      if (startDate > now || now >= endDate) {
        storage.clearDisplayedHearingDialogCount(master.survey_type)
        return false
      }
      // アンケート表示回数チェック
      const count = storage.getDisplayedHearingDialogCount(master.survey_type)
      if (count && count > 0) { return false }
      return true
    })

    return masters.length === 0 ? [] : masters
  }

  const handleErrorClose = () => {
    setErrorOpen(false)
    props.setScreen('Landing')
  }

  if (isNullOrUndefined(friends)) {
    return (
      <ConfirmDialog
        open={errorOpen}
        onClose={handleErrorClose}
        confirm="yes"
        title={'ネットワーク障害'}
        message={'ネットワーク障害が発生しました。'}
      />
    )
  }

  const closeGraduatedDialog = async () => {
    try {
      const friend = await Api.updateFriend(friendState.id, { graduated_message_displayed: true })
      props.setFriend(friend)
      setOpenGraduatedDialog(false)
      setOpenHappinessSurveyDialog(true)
      setFriendState(friend)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const closeTutorial = async () => {
    try {
      await BaseComponent.updateAlreadyReadTutorial(props, 'app')
      setOpenTutorial(false)
      props.setHideAppNavigation(false)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const closeDemoNotificationDialog = () => {
    BaseComponent.createReadStateDialogDisplayed(props, 'demo_notification_dialog')
    setOpenDemoNotificationDialog(false)
  }

  const moveAiChatRoom = async () => {
    const ai_chat_room_id = user.ai_chat_room_id
    if (!ai_chat_room_id) { return }
    const aiFriend = await BaseComponent.getAiFriend(props, ai_chat_room_id)
    if (aiFriend) {
      BaseComponent.createReadStateDialogDisplayed(props, 'demo_notification_dialog')
      setOpenDemoNotificationDialog(false)
      props.setFriend(aiFriend)
      props.setScreen('Chat')
    }
  }

  const closeUserHearingDialog = (index) => {
    setNotAnsweredMasters(notAnsweredMasters.filter((_, i) => i !== index))
  }

  const displayedUserHearingDialog = (index) => {
    const surveyType = notAnsweredMasters[index].survey_type
    let count = Number(storage.getDisplayedHearingDialogCount(surveyType))
    if (!count) {
      count = 1
    } else {
      count += 1
    }
    storage.setDisplayedHearingDialogCount(surveyType, count)
  }

  const closePhotoTutorialDialog = () => {
    storage.setLastDatePhotoTutorialDisplayed(new Date())
    setOpenPhotoTutorialDialog(false)
  }

  return (
    <div>
      <NotifyDialog {...props} />
      <GraduatedDialog
        open={openGraduatedDialog}
        friend={friendState}
        onClose={closeGraduatedDialog}
      />
      {openTutorial && (
        <Tutorial {...props} onClose={closeTutorial} />
      )}
      <HappinessSurveyDialog
        {...props}
        open={openHappinessSurveyDialog}
        onClose={() => setOpenHappinessSurveyDialog(false)}
        timingType={isLover ? 'graduate' : 'regular'}
      />
      <DemoNotificationDialog
        {...props}
        open={openDemoNotificationDialog}
        onClose={closeDemoNotificationDialog}
        onOk={moveAiChatRoom}
      />
      {notAnsweredMasters.map((master, index) => (
        <UserHearingDialog
          {...props}
          key={master.id}
          open={true}
          onClose={() => closeUserHearingDialog(index)}
          onDisplayed={() => displayedUserHearingDialog(index)}
          master={master}
        >
          <SurveyTopDialog
            {...props}
            master={master}
            onClose={() => closeUserHearingDialog(index)}
          />
        </UserHearingDialog>
      ))}
      <PhotoTutorialDialog
        {...props}
        open={openPhotoTutorialDialog}
        onClose={closePhotoTutorialDialog}
        type='dialog'
      />
      <ConfirmDialog
        open={errorOpen}
        onClose={handleErrorClose}
        confirm="yes"
        title={'ネットワーク障害'}
        message={'ネットワーク障害が発生しました。'}
      />
      {(!openTutorial && graduationConfirmationed) && (
        isLover ? <Chat {...props} /> : <FriendHome {...props} />
      )}
    </div>
  )
}

Home.propTypes = {
  user: PropTypes.object,
  requestSuccessMessage: PropTypes.string,
  friends: PropTypes.array,
  setScreen: PropTypes.func,
  setHideAppNavigation: PropTypes.func,
  setFriend: PropTypes.func,
  setMyOrganization: PropTypes.func,
  tutorialReadStates: PropTypes.array,
}