import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import BackgroundImage from 'images/approve-push/dialog_bg.png'
import Female from 'images/approve-push/female.png'
import Komainu from 'images/approve-push/komainu.png'
import Male from 'images/approve-push/male.png'
import Speech from 'images/approve-push/speech.png'
import SpeechBubble from 'images/approve-push/speech_bubble.png'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    width: 328,
    maxWidth: 328,
    height: 640,
  },
  title: {
    padding: theme.spacing(4.75),
  },
  content: {
    padding: 0,
  },
  topText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#616AFA',
    lineHeight: 1,
    background: '#EFF0FF',
    borderRadius: 5,
    height: 38,
    width: 206,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
  },
  bubbleText: {
    fontSize: 20,
    fontWeight: 600,
    color: '#616AFA',
    lineHeight: '32px',
  },
  bubble: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(-3.75),
  },
  mainText: {
    fontSize: 30,
    fontWeight: 700,
    color: '#616AFA',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    maxWidth: 268,
    width: 'fit-content',
  },
  speech: {
    width: 'fit-content',
    margin: 'auto',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3.75),
    position: 'relative',
    '& p': {
      fontSize: 12,
      textAlign: 'left',
      position: 'absolute',
      top: 0,
      left: 20,
    },
  },
  miniText: {
    fontSize: 12,
    fontWeight: 600,
    color: '#616AFA',
    lineHeight: '20px'
  },
  actions: {
    paddingTop: 0,
    '& img': {
      position: 'relative',
      top: 1,
    },
  },
  marker: {
    background: 'linear-gradient(transparent 70%, #FFFCBE 0%)',
    padding: '6 4px',
  },
}))

export default function ApprovePushDialog (props) {
  const { open, onClose, user } = props
  const classes = useStyles()

  if (!user) { return null }

  const createSpeech = () => {
    if (user.sex === 'male') {
      return (
        <Grid className={classes.speech} container justifyContent="center" alignItems="center">
          <img src={Speech} width={171} height={77} />
          <img src={Male} width={70} height={64} />
          <Typography>
            <span style={{ fontWeight: 500 }}>第一印象は“普通”でも</span><br />
            <span style={{ fontWeight: 700 }}>
              トークで意気投合し<br />
              交際が始まりました！
            </span>
          </Typography>
        </Grid>
      )
    } else if (user.sex === 'female') {
      return (
        <Grid className={classes.speech} container justifyContent="center" alignItems="center">
          <img src={Speech} width={171} height={77} />
          <img src={Female} width={70} height={64} />
          <Typography>
            <span style={{ fontWeight: 500 }}>タイプは異なりましたが</span><br />
            <span style={{ fontWeight: 700 }}>
              トークで意気投合し<br />
              交際が始まりました！
            </span>
          </Typography>
        </Grid>
      )
    }
  }

  return (
    <>
      <DialogThemeProvider color={'default'}>
        <Dialog open={open} PaperProps={{ className: classes.paper }}>
          <DialogCloseButton onClick={onClose} />
          <DialogTitle className={classes.title} disableTypography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Typography className={classes.topText}>気軽にトークをはじめよう！</Typography>
            <Typography className={classes.bubbleText}>
              Aill goenで<br />
              お付き合いした人は
            </Typography>
            <img className={classes.bubble} src={SpeechBubble} width={224} height={58} />
            <Typography className={`${classes.mainText} ${classes.marker}`} style={{ marginBottom: 8 }}>
              <span style={{ fontSize: 48, color: '#423BC7' }}>10</span>
              <span style={{ fontSize: 32 }}>人以上と</span>
            </Typography>
            <Typography className={`${classes.mainText} ${classes.marker}`}>
              <span style={{ color: '#423BC7' }}>トーク</span>しています！
            </Typography>
            {createSpeech()}
            <Typography className={classes.miniText}>
              プロフィールでは見えない魅力が<br />
              トークで見えるよ！
            </Typography>
          </DialogContent>
          <DialogActions className={classes.actions} disableSpacing>
            <img src={Komainu} width={97} height={43} />
            <Button variant="contained" onClick={() => {
              onClose()
              props.setScreen('FriendRequest')
            }}>
              「いいね！」を確認する
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    </>
  )
}

ApprovePushDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  setScreen: PropTypes.func,
}
