import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { PhotoFeatures } from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import BackgroundImage from 'images/photo-advice/dialog_bg.png'
import Illustration from 'images/photo-advice/dialog_illustration.png'
import Title from 'images/photo-advice/dialog_title.png'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
  },
  title: {
    padding: theme.spacing(5.5),
  },
  content: {
    marginBottom: theme.spacing(-5.75),
    '& img:nth-of-type(1)': {
      maxWidth: '273px !important',
      position: 'relative',
      left: -18,
    },
  },
  text: {
    fontSize: 16,
    color: '#423BC7',
    position: 'relative',
    top: 4,
  },
  button: {
    color: '#423BC7',
    backgroundColor: '#ffffff !important',
    textTransform: 'none',
    boxShadow: '0px 1px 3px #00000029 !important',
  },
  actions: {
    paddingTop: 0,
    paddingBottom: theme.spacing(8.5),
    '& img': {
      position: 'relative',
      top: 1,
    },
  },
}))

export default function AdvicePhotoDialog (props) {
  const { user, open, onClose } = props
  const classes = useStyles()

  if (!user) { return null }

  const toPhotoAdvice = async () => {
    props.setLoading(true)
    try {
      const galleryItems = await Api.getMyGalleryItems()
      const allFeatures = Array.from(new Set(
        [
          user?.photo_features,
          user?.overall_photo_features,
          galleryItems.map(item => item?.features),
        ].flat(2).filter(Boolean)
      ))
      const features = preparePhotoFeatures(allFeatures)
      const sorted = sortPhotoFeatures(features)
      props.setAdvicePhoto(sorted)
      onClose()
      if (0 < sorted.length) {
        props.setScreen('PhotoAdvice')
      } else {
        BaseComponent.showRequestSuccessMessage(props, '写真へのアドバイスはありません')
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  // 顔がよく見えない系の重複を排除する
  const preparePhotoFeatures = (features) => {
    const faceFeatures = [
      PhotoFeatures.FROM_SIDE,
      PhotoFeatures.HIDDEN_FACE,
      PhotoFeatures.SMALL_FACE,
    ]
    const faceFeature = faceFeatures.find(v => features.includes(v))
    if (faceFeature) {
      faceFeatures.filter(v => v !== faceFeature).forEach(v => {
        const idx = features.indexOf(v)
        if (-1 < idx) { features.splice(idx, 1) }
      })
    }
    return features
  }

  // 表示優先順にソート
  const sortPhotoFeatures = (features) => {
    const order = [
      PhotoFeatures.FROM_SIDE,
      PhotoFeatures.HIDDEN_FACE,
      PhotoFeatures.SMALL_FACE,
      PhotoFeatures.LOW_QUALITY,
      PhotoFeatures.TOO_PROCESSING,
      PhotoFeatures.NOT_CLEAN,
      PhotoFeatures.LOW_BRIGHTNESS,
      PhotoFeatures.NO_EXPRESSION,
      PhotoFeatures.BAD_BACKGROUND,
      PhotoFeatures.BAD_SELFIE,
      PhotoFeatures.BETTER_TO_MAIN,
      PhotoFeatures.FEW_GALLERY_ITEMS,
      PhotoFeatures.FEW_FACES,
    ]
    return features.filter(v => order.includes(v)).sort((a, b) => {
      return order.indexOf(a) - order.indexOf(b)
    })
  }
  return (
    <>
      <DialogThemeProvider color={'default'}>
        <Dialog open={open} PaperProps={{ className: classes.paper }}>
          <DialogCloseButton onClick={onClose} />
          <DialogTitle className={classes.title} disableTypography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Typography className={classes.text}>
              {user.nick_name}さん専用
            </Typography>
            <img src={Title} width={273} height={261} />
          </DialogContent>
          <DialogActions className={classes.actions} disableSpacing>
            <img src={Illustration} width={132} height={126} />
            <Button className={classes.button} variant="contained" onClick={toPhotoAdvice}>
              専用アドバイスへ
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>

    </>
  )
}

AdvicePhotoDialog.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
  setAdvicePhoto: PropTypes.func,
}
