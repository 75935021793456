import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import EntrySteps from 'components/entry/EntrySteps'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import CountrySelect from 'components/parts/CountrySelect'
import SmsSettingsNotice from 'components/parts/SmsSettingsNotice'
import Api from '../../commons/api'
import { cleanPhoneNumber, formatPhoneNumber, validatePhoneNumber } from '../../commons/utility'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(22),
    maxWidth: 700,
    margin: 'auto',
    overflowX: 'hidden',
  },
  content: {
    paddingTop: theme.spacing(2),
    maxWidth: 300,
  },
  text1: {
    fontSize: 14,
    color: '#707070',
  },
  text2: {
    fontSize: 14,
    color: '#423BC7',
  },
  formContainer: {
    marginTop: theme.spacing(4),
  },
  input: {
    borderRadius: 8,
    border: '1px solid #999999',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.625),
  },
  button: {
    marginLeft: theme.spacing(2.75),
    marginRight: theme.spacing(2.75),
  },
  entry: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(2),
  },
  back: {
    ...theme.styles.dialogOkButton,
    fontSize: 12,
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(5.25),
  },
  buttons: {
    position: 'fixed',
    bottom: 28,
    left: '50%',
    transform: 'translate(-50%, -0%)',
  }
}))

const PhoneNumber = (props) => {
  const { ...other } = props
  const classes = useStyles()

  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryItems, setCountryItems] = useState([])
  const [countryCode, setCountryCode] = useState('JP')
  const [isValid, setIsValid] = useState(false)
  const [isVerified, setIsVerified] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    BaseComponent.addVisitPageLog()
    loadData()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const loadData = async () => {
    props.setLoading(true)
    try {
      const auth = await Api.getAuth()
      if (auth.phone_number && auth.is_phone_verified) {
        setFormattedPhoneNumber(auth.phone_number)
        setIsVerified(true)
      }

      const cItems = await Api.getCountries()
      cItems.sort((c1, c2) => c1.display_name.localeCompare(c2.display_name))
      setCountryItems(cItems)
    } catch (err) {
      BaseComponent.handleApiError(err)
    } finally {
      props.setLoading(false)
    }
  }

  const handlePhoneNumberChange = (e) => {
    setFormattedPhoneNumber(e.target.value)
  }

  const setFormattedPhoneNumber = (num) => {
    const formattedPhoneNumber = formatPhoneNumber(num)
    setPhoneNumber(formattedPhoneNumber)
    setIsValid(validatePhoneNumber(formattedPhoneNumber))
  }

  const handleNext = async () => {
    if (isVerified) {
      props.setScreen('Love')
      return
    }

    const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber)

    props.setLoading(true)
    try {
      await Api.updatePhoneNumber(props.authId, cleanedPhoneNumber)
      BaseComponent.toEntryNextStep(props, props.user)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleChangePhoneNumber = () => {
    setPhoneNumber('')
    setIsValid(false)
    setIsVerified(false)
  }

  return (
    <div className={classes.root}>
      <AppHeader title="基本情報" hideBack={true} {...other} />
      <EntrySteps step={0} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
      <Grid container direction={'row'} justifyContent="center">
        <Grid container className={classes.content}>
          <Typography className={classes.text1}>
            不正利用を防ぎ、安心安全なご利用のために<br />
            電話番号のSMS（ショートメッセージ）を利用した認証を必須としています。<br />
            スキップされる場合は、アプリログイン後に設定をお願いします。
          </Typography>
          <Typography className={classes.text2}>電話番号がプロフィール情報に記載されることはありません。</Typography>
          <Grid container className={classes.formContainer}>
            <Typography>電話番号</Typography>
            {!isVerified && (
              <CountrySelect
                items={countryItems}
                value={countryCode}
                onChange={newVal => setCountryCode(newVal)}
              />
            )}
            <TextField
              type={'tel'}
              className={classes.input}
              fullWidth
              InputProps={{ disableUnderline: true }}
              value={formatPhoneNumber(phoneNumber)}
              onChange={handlePhoneNumberChange}
              disabled={isVerified}
              inputProps={{ 'data-testid': 'phone-number-input' }}
            />
          </Grid>
          <SmsSettingsNotice title="事前にご確認ください" />
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.buttons}>
          <Grid item className={classes.button}>
            <Button variant="contained" color="secondary" className={classes.entry} disabled={!isValid} onClick={handleNext}>
              次へ
            </Button>
          </Grid>
          {!isVerified && (
            <Grid item className={classes.button}>
              <Button variant="contained" color="secondary" className={classes.entry} onClick={() => props.setScreen('Love')}>
                スキップ
              </Button>
            </Grid>
          )}
          {isVerified && (
            <Grid item className={classes.button}>
              <Button variant="contained" color="secondary" className={classes.entry} onClick={handleChangePhoneNumber}>
                電話番号を変更
              </Button>
            </Grid>
          )}
          <Grid item className={classes.button}>
            <Button variant="text" color="secondary" className={classes.back} onClick={() => props.setScreen('Entry')}>
              戻る
            </Button>
          </Grid>
        </Grid>
      </Grid>

    </div>
  )
}

export default PhoneNumber

PhoneNumber.propTypes = {
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
  user: PropTypes.object,
  authId: PropTypes.string,
}
