import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { formatJapaneseDateTime } from 'commons/utility'
import SendConfirmDialog from 'components/chats/SendConfirmDialog'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import CropDialog from 'components/parts/CropDialog'
import ScheduledChatDeleteConfirmDialog from 'components/scheduled-chat-messages/ScheduledChatDeleteConfirmDialog'
import ScheduledChatEditDialog from 'components/scheduled-chat-messages/ScheduledChatEditDialog'
import ScheduledChatMenuDialog from 'components/scheduled-chat-messages/ScheduledChatMenuDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.styles.header.height,
  },
  title: {
    padding: theme.spacing(2),
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
  },
  item: {
    width: '100%',
    borderBottom: '1px solid #E0E0E0',
    padding: theme.spacing(2),
    gap: theme.spacing(1.25),
    userSelect: 'none',
    msUserSelect: 'none',
    '& p': {
      userSelect: 'none',
      msUserSelect: 'none',
      '-webkit-user-select': 'none',
    },
    '&:first-child': {
      borderTop: '1px solid #E0E0E0',
    },
  },
  itemHeader: {
    gap: theme.spacing(1),
  },
  scheduled_date: {
    fontSize: 12,
    lineHeight: 1,
    color: '#707070',
  },
  message: {
    fontSize: 14,
    color: '#414141',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  send_to: {
    fontSize: 12,
    lineHeight: 1,
    color: '#423BC7',
  },
  photo: {
    width: '50%',
    height: 'auto',
  }
}))
const ScheduledChatMessages = (props) => {
  const {
    friend,
    setLoading,
  } = props
  const classes = useStyles()
  const [scheduledMessages, setScheduledMessages] = useState([])
  const [openMenu, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedMessageId, setSelectedMessageId] = useState(null)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openSendConfirm, setOpenSendConfirm] = useState(false)
  const [openCropDialog, setOpenCropDialog] = useState(false)
  const [originalFile, setOriginalFile] = useState(null)
  const [imageFileUrl, setImageFileUrl] = useState(null)

  const fileInputRef = useRef()
  const intervalTimerRef = useRef()

  const fetchScheduledMessages = async () => {
    setLoading(true)
    try {
      const messages = await Api.getMyScheduledChatMessages(friend.id)
      const promises = messages.map(async message => {
        if (!message.photo_id) return message
        const photo = await Api.getChatPhotoItem(message.chat_room_id, message.photo_id)
        return { ...message, photo_url: photo.preview_url }
      })
      Promise.all(promises).then(setScheduledMessages)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchScheduledMessages()
  }, [friend, setLoading])

  const resetMenu = () => {
    setAnchorEl(null)
    setSelectedMessageId(null)
    setOpenMenu(false)
  }

  const handleMenuClose = () => {
    resetMenu()
  }

  const handleMenuEdit = () => {
    const message = scheduledMessages.find((m) => m.id === selectedMessageId)
    if (message.photo_id) {
      onChooseFile()
      return
    }
    setOpenEditDialog(true)
  }

  const handleDelete = async () => {
    props.setLoading(true)
    try {
      await Api.deleteScheduledChatMessage(selectedMessageId)
      await fetchScheduledMessages()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      resetMenu()
      setOpenDeleteConfirm(false)
    }
  }

  const onEditScheduledMessage = async (message) => {
    props.setLoading(true)
    try {
      await Api.updateScheduledChatMessage(selectedMessageId, { message })
      await fetchScheduledMessages()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      resetMenu()
      setOpenEditDialog(false)
    }
  }

  const handleMessageClick = (_, id) => {
    setSelectedMessageId(id)
    const message = scheduledMessages.find((m) => m.id === id)
    if (message.photo_id) {
      onChooseFile()
      return
    }
    setOpenEditDialog(true)
  }

  const handleSendNow = async () => {
    props.setLoading(true)
    try {
      const targetMessage = scheduledMessages.find((m) => m.id === selectedMessageId)
      const message_type = targetMessage.photo_id ? 'image' : 'message'
      await Api.sendChatMessage(friend.chat_room_id, {
        message_type,
        message: targetMessage.message,
        reply_to: targetMessage.reply_to,
        photo_id: targetMessage.photo_id,
      })
      await Api.deleteScheduledChatMessage(selectedMessageId)
      await fetchScheduledMessages()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      resetMenu()
      setOpenSendConfirm(false)
      BaseComponent.showRequestSuccessMessage(props, '予約中のメッセージを送信しました')
    }
  }

  const handleSendScheduled = async (scheduled_at) => {
    props.setLoading(true)
    try {
      await Api.updateScheduledChatMessage(selectedMessageId, { scheduled_at })
      await fetchScheduledMessages()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      resetMenu()
      setOpenSendConfirm(false)
    }
  }

  const onFileChange = (e) => {
    const file = e.target.files[0]
    setOriginalFile(file)
    const reader = new FileReader()
    reader.onload = () => {
      setImageFileUrl(reader.result)
      setOpenCropDialog(true)
    }
    reader.readAsDataURL(file)
  }

  const closeCropDialog = () => {
    setOpenCropDialog(false)
    setOriginalFile(null)
    setImageFileUrl(null)
    fileInputRef.current.value = ''
    resetMenu()
  }

  const uploadCroppedFile = async (file) => {
    props.setLoading(true)
    try {
      const roomId = friend.chat_room_id
      const photo = await BaseComponent.addChatImage(roomId, file)
      await Api.updateScheduledChatMessage(selectedMessageId, { photo_id: photo.id })
      await fetchScheduledMessages()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      closeCropDialog()
    }
  }

  const onChooseFile = () => {
    fileInputRef.current?.click()
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
  }

  const handleTouchStart = (event, id) => {
    const el = event.currentTarget
    intervalTimerRef.current = setTimeout(() => {
      setAnchorEl(el)
      setSelectedMessageId(id)
      setOpenMenu(true)
    }, 1000)
  }

  const handleTouchMove = () => {
    if (intervalTimerRef.current) {
      clearTimeout(intervalTimerRef.current)
      intervalTimerRef.current = null
    }
  }

  const handleTouchEnd = () => {
    if (intervalTimerRef.current) {
      clearTimeout(intervalTimerRef.current)
      intervalTimerRef.current = null
    }
  }

  return (
    <>
      <AppHeader title={friend.nick_name} target={props.friend} toggleMenu={true} backWhite={true} showFriendIcon={true} {...props} />
      <Grid container className={classes.root} direction={'column'}>
        <Typography className={classes.title}>
          送信予約中のメッセージ
        </Typography>
        <Grid container direction={'column'}>
          {
            scheduledMessages.map((message, index) => (
              <Grid
                className={classes.item}
                container
                direction={'column'}
                key={index}
                onClick={(e) => handleMessageClick(e, message.id)}
                onContextMenu={(e) => handleContextMenu(e)}
                onTouchStart={(e) => handleTouchStart(e, message.id)}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <Grid container className={classes.itemHeader} direction={'column'}>
                  <Typography className={classes.scheduled_date} style={
                    message.status === 'error'
                      ? { color: '#FF0000' }
                      : {}
                  }>
                    {message.status === 'scheduled'
                      ? `${formatJapaneseDateTime(message.scheduled_at)}に送信予約中`
                      : `${formatJapaneseDateTime(message.scheduled_at)}に送信失敗しました`
                    }
                  </Typography>
                  <Typography className={classes.send_to}>
                    送信先：
                    {
                      message.secret_question_title
                        ? `秘密の質問部屋（${message.secret_question_title}）`
                        : `${friend.nick_name}さんとのトーク`
                    }
                  </Typography>
                </Grid>
                {message.photo_id
                  ? <img className={classes.photo} src={message.photo_url}/>
                  : <Typography className={classes.message}>{message.message}</Typography>
                }
              </Grid>
            ))
          }
        </Grid>
      </Grid>
      <ScheduledChatMenuDialog
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        onDelete={() => setOpenDeleteConfirm(true)}
        onEdit={handleMenuEdit}
        onSendNow={handleSendNow}
        onReschedule={() => setOpenSendConfirm(true)}
      />
      <ScheduledChatDeleteConfirmDialog
        open={openDeleteConfirm}
        onOK={handleDelete}
        onCancel={() => setOpenDeleteConfirm(false)}
      />
      <ScheduledChatEditDialog
        open={openEditDialog}
        onCancel={() => setOpenEditDialog(false)}
        friend={friend}
        onEdit={onEditScheduledMessage}
        onDelete={handleDelete}
        message={scheduledMessages.find((m) => m.id === selectedMessageId)}
        {...props}
      />
      <SendConfirmDialog
        {...props}
        open={openSendConfirm}
        onCancel={() => setOpenSendConfirm(false)}
        onSendNow={handleSendNow}
        onSendScheduled={handleSendScheduled}
      />
      <input ref={fileInputRef} type="file" accept="image/*" style={{ display: 'none' }} onChange={onFileChange}/>
      <CropDialog
        {...props}
        open={openCropDialog}
        originalFile={originalFile}
        photoUrl={imageFileUrl}
        onUpload={uploadCroppedFile}
        onClose={closeCropDialog}
        aspectRatio={null}
        isSend={true}
      />
    </>
  )
}

export default ScheduledChatMessages

ScheduledChatMessages.propTypes = {
  friend: PropTypes.object,
  user: PropTypes.object,
  setLoading: PropTypes.func,
  setFriend: PropTypes.func,
  setScreen: PropTypes.func,
}
